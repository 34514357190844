import { AxiosResponse } from "axios";
import { IAvatars } from "../@types/IAvatars";
import { ILawyerInformation } from "../@types/ILawyerInformation";
import Backend from "../config/backendAdress";
import { UserProps } from "../hooks/auth";
import api from "../services/api";
import { GetLawyerInformation } from "./RoutesFunctions/functions.Lawyers";

interface LoadUsersAvatarProps {
  usersId: string[];
}

export async function LoadUsersAvatar({
  usersId,
}: LoadUsersAvatarProps): Promise<AxiosResponse<IAvatars>[]> {
  const RequestsPromises = usersId.map(async (userId) => {
    return await api.get<IAvatars>(`/avatar/id/${userId}`);
  });
  return Promise.all(RequestsPromises);
}

interface ShortFileNameProps {
  name: string;
  maxCaractereLimit?: number;
}

export function HandleFileName({
  maxCaractereLimit,
  name,
}: ShortFileNameProps): string {
  if (!maxCaractereLimit || maxCaractereLimit === -1) {
    return name.slice(33);
  }
  if (name.length >= maxCaractereLimit) {
    return `${name.slice(33).substr(0, maxCaractereLimit)}...`;
  } else {
    return name.slice(33);
  }
}

interface HandleLinksProps {
  FileName: string;
}

export function HandleLink({ FileName }: HandleLinksProps): string {
  return `${Backend.files}/${FileName}`;
}

export function WriteCorrectPlanName(
  plan: ILawyerInformation["plan_type"]
): string {
  if (plan === "partneshipMember") {
    return `Partneship Member`;
  }
  if (plan === "membership") {
    return `Membership`;
  }
  if (plan === "professional") {
    return `Professional`;
  }

  return `Starter`;
}

interface GetIuguClientIdFromLawyerProps {
  userId: string;
  lawyerData: ILawyerInformation;
  updateLawyerData(): Promise<AxiosResponse<ILawyerInformation> | void>;
}

interface VariableFromUserType {
  ToUser: any;
  ToLawyer: any;
}

export function VariableElementFromUserType(
  user: UserProps,
  { ToLawyer, ToUser }: VariableFromUserType
) {
  if (user.type === "lawyer") {
    return ToLawyer;
  }
  if (user.type === "user") {
    return ToUser;
  }
  return ToUser;
}

export interface UrlParamsTypes {
  actionType: "office" | "newChat" | "newJuridicCorrespondenceRequest";
  data: string;
  office: string | undefined;
}

interface setContactLinkProps2 {
  user?: UserProps | undefined;
  contact_user_id: string;
  contactType: UrlParamsTypes["actionType"];
}

export function GetNewChatContactLinkByUserId({
  user,
  contact_user_id,
  contactType,
}: setContactLinkProps2): string {
  const link = user
    ? user.type === "lawyer"
      ? `/escritorio/chat/${contactType}/${contact_user_id}`
      : user.type === "user"
      ? `/painel/chat/${contactType}/${contact_user_id}`
      : ""
    : "";
  return link;
}

export async function GetIuguCostumerIdFromLocalLawyer({
  lawyerData,
  userId,
  updateLawyerData,
}: GetIuguClientIdFromLawyerProps): Promise<string | null> {
  if (!lawyerData.user_payment_reference) {
    const lawyerInformation = await GetLawyerInformation(userId);
    await updateLawyerData();
    return lawyerInformation.data.user_payment_reference || null;
  }
  return lawyerData.user_payment_reference;
}
interface UrlProps {
  SetUrl(url: React.SetStateAction<string>): void;
}
export const HandleUrlValidation = (
  text: string,
  { SetUrl }: UrlProps
): string => {
  const a = "àáäâãèéëêìíïîòóöôùúüûñçßÿœæŕśńṕẃǵǹḿǘẍźḧ·/-,:;1234567890";
  const b = "aaaaaeeeeiiiioooouuuuncsyoarsnpwgnmuxzh______";
  const p = new RegExp(a.split("").join("|"), "g");
  const newText = text
    .toString()
    .toLowerCase()
    .trim()
    .replace(p, (c: any) => b.charAt(a.indexOf(c))) // Replace special chars
    .replace(/&/g, "_and_") // Replace & with 'and'
    .replace(/[\s\W-]+/g, "_") // Replace spaces, non-word characters and dashes with a single dash (-)
    .replace(/[\s\W__]+/g, "_");

  SetUrl(newText);
  return newText;
};
