import styled, { css } from "styled-components";

interface ThemeTypes {
  darkTheme?: boolean;
}

export const FakeInput = styled.button`
  height: 3rem;
  width: 100%;
  border: none;

  background: ${(props) => props.theme.colors.white};
  border-radius: 1.5rem;
  padding: 0.7rem;

  display: flex;
  align-items: center;

  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);

  cursor: pointer;

  span {
    color: ${(props) => props.theme.colors.lightText};
    margin-left: 1rem;
  }
  svg {
    margin-left: auto;
    margin-right: 1rem;
  }
`;
export const Rectangle = styled.div`
  width: 4rem;
  height: 0.2rem;
  background: ${(props) => props.theme.colors.primary};
`;

export const StyledButton = styled.button`
  display: flex;
  align-items: center;

  cursor: pointer;
  padding: 0.5rem 1rem 0.5rem 1rem;

  background-color: ${(props) => props.theme.colors.primary};
  border: 2px solid ${(props) => props.theme.colors.primary};
  border-radius: 1rem;

  font-weight: 600;
  color: ${(props) => props.theme.colors.title};

  transition: all 0.2s ease-in-out;

  svg {
    margin-right: 0.5rem;
  }

  &:hover {
    background-color: ${(props) => props.theme.colors.whiteOrDark};
    border-color: ${(props) => props.theme.colors.primary};
  }

  &:disabled {
    opacity: 0.5;
  }
`;

export const SearchLocation = styled.div`
  .react-select-container-mainPage {
    .react-select-mainPage__control {
      box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
      background: ${(props) => props.theme.colors.whiteOrDark};
      border-radius: 1.2rem 0rem 0 1.2rem;

      color: ${(props) => props.theme.colors.text};
      border: 2px solid ${(props) => props.theme.colors.whiteOrDark};

      padding: 6px;
      padding-left: 1.6rem;

      width: 100%;
    }
  }
  .react-select__menu-mainPage {
    z-index: 999;
  }
  .react-select-container-location {
    .react-select-location__control {
      box-shadow: 0px 10px 15px -10px rgba(0, 0, 0, 0.25);

      background: ${(props) => props.theme.colors.whiteOrDark};
      border-radius: 0rem 0rem 0rem 0rem;

      color: ${(props) => props.theme.colors.text};
      border: 2px solid ${(props) => props.theme.colors.whiteOrDark};

      padding: 6px;
      padding-left: 1.6rem;

      width: 100%;
    }
  }
  .react-select-location__menu {
    z-index: 999;
  }

  button {
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);

    svg {
      display: flex;
      width: 100%;
      height: 100%;
      padding: 0.8rem;
      align-items: center;
      justify-content: center;
    }

    transition: background-color 0.2s;

    &:hover {
      background-color: ${(props) => props.theme.colors.primaryDarker};
    }
  }
`;
interface TogglesProps {
  isActive: boolean;
  isDark?: boolean;
}
export const TogglesSelect = styled.button<TogglesProps>`
  border: none;
  background: none;
  color: ${(props) => props.theme.colors.text};
  padding: 0.5rem 1.2rem 0.5rem 1.2rem;
  transition: all 0.2s;
  font-weight: 500;
  margin: 0.1rem;

  border-bottom: 0.1rem solid rgba(255, 255, 255, 0);
  ${(props) =>
    props.isActive
      ? css`
          border-bottom: 0.16rem solid ${(props) => props.theme.colors.primary};

          &:hover {
            border-bottom: 0.15rem solid
              ${(props) => props.theme.colors.primaryDarker};
          }
        `
      : css`
          &:hover {
            border-bottom: 0.15rem solid
              ${(props) => props.theme.colors.primary};
          }
        `}
  ${(props) =>
    props.isDark &&
    css`
      color: ${(props) => props.theme.colors.text};
    `}
`;

export const TogglesSelected = styled.button`
  margin-right: 3rem;
`;

export const SearchTogglesArea = styled.div<ThemeTypes>`
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  display: flex;
  width: 100vw;
  justify-content: center;
  align-items: center;
  background: ${(props) => props.theme.colors.whiteOrDark};

  .TogglesSelected {
  }

  .TogglesSelect {
    ${(props) =>
      props.darkTheme
        ? css`
            border: 0.1rem solid ${(props) => props.theme.colors.darkOrWhite};
            color: ${(props) => props.theme.colors.darkOrWhite};
          `
        : ""}
  }
`;

export const OptionsArea = styled.div`
  padding: 1rem;
`;

export const Option = styled.div<TogglesProps>`
  padding: 1rem;
  box-shadow: 0px 0px 15px rgba(214, 214, 214, 0);
  //border-radius: 1.5rem;

  border: 0px solid rgba(255, 255, 255, 0);
  border-bottom: 1px solid rgba(255, 255, 255, 0);
  border-top: 1px solid rgba(255, 255, 255, 0);

  transition: all 0.2s ease-in-out;
  width: 100vw;

  cursor: pointer;

  ${(props) =>
    props.isActive &&
    css`
      border-bottom: 1px solid ${(props) => props.theme.colors.primary};
      border-top: 1px solid ${(props) => props.theme.colors.primary};
      background-color: rgba(214, 214, 214, 0.25);
    `}
`;
