import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
interface FilterOptionProps {
  active?: boolean;
}
export const FilterOption = styled.div<FilterOptionProps>`
  display: flex;
  flex-direction: row;
  align-items: center;

  justify-content: center;

  padding: 0.5rem 1rem;

  margin: 0 0.5rem;

  border: 1px solid ${p => p.theme.colors.primary};

  font-size: 0.8rem;

  background: transparent;

  transition: all 0.2s ease-in-out;

  cursor: pointer;

  border-radius: 1rem;

  ${p =>
    p.active &&
    css`
      border: 1px solid ${p => p.theme.colors.primary};
      background: ${p => p.theme.colors.primary};
    `}

  &:hover {
    border: 1px solid ${p => p.theme.colors.primary};
    background: ${p => p.theme.colors.primary};
  }
`;
