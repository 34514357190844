import { PauseOutlined } from "@ant-design/icons";
import { Button, Dropdown, Menu, PageHeader } from "antd";
import React from "react";
import { useAuth } from "../../hooks/auth";
const PageHeaderCP: React.FC = () => {
  const { signOut } = useAuth();
  const HeaderDropdownOptions = (
    <Menu>
      <Menu.Item onClick={() => signOut()}>Sair</Menu.Item>
    </Menu>
  );

  return (
    <>
      <PageHeader
        backIcon={false}
        ghost={false}
        onBack={() => window.history.back()}
        title="Admin"
        subTitle="DashBoard"
        extra={[
          <Dropdown
            key="3"
            overlay={HeaderDropdownOptions}
            placement="bottomCenter"
            arrow
          >
            <Button>
              <PauseOutlined />
            </Button>
          </Dropdown>,
        ]}
      />
    </>
  );
};

export default PageHeaderCP;
