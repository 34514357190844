import React from "react";
import "./App.less";
import "dotenv/config";

import Routes from "./routes";
import { AppProvider } from "./hooks";
import GlobalStyle from "./styles/globalStyle";
import { BrowserRouter } from "react-router-dom";
function App() {
  return (
    <AppProvider>
      <BrowserRouter>
        <Routes />
      </BrowserRouter>

      <GlobalStyle />
    </AppProvider>
  );
}

export default App;
