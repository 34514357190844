import styled, { css } from "styled-components";

export const DefaultButton = styled.button`
  display: flex;
  align-items: center;

  cursor: pointer;
  padding: 0.3rem 1rem 0.3rem 1rem;

  background-color: ${(props) => props.theme.colors.primary};
  border: 0.4rem solid ${(props) => props.theme.colors.primary};
  border-radius: 1rem;

  font-weight: 600;
  color: ${(props) => props.theme.colors.title};

  transition: all 0.5s;

  svg {
    margin-right: 0.5rem;
  }

  &:hover {
    background-color: ${(props) => props.theme.colors.primaryDarker};
    border-color: ${(props) => props.theme.colors.primaryDarker};
  }

  &:disabled {
    opacity: 0.5;
  }
`;

interface TransparentButtonProps {
  darkBackground?: boolean;
}

export const TransparentButton = styled.button<TransparentButtonProps>`
  border: none;
  background: none;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0.8rem 1.5rem;
  border-bottom: 2px solid ${(props) => props.theme.colors.primary};

  transition: all 0.2s ease-in-out;
  svg {
    margin-right: 0.3rem;
  }
  font-size: 0.9rem;

  &:hover {
    ${(props) =>
      props.darkBackground
        ? css`
            background: rgba(240, 240, 240, 0.1);
          `
        : css`
            background: rgba(240, 240, 240, 0.9);
          `}
  }
`;
