import { AxiosResponse } from "axios";
import { IUser } from "../../@types/IUser";
import { IUserInformation } from "../../@types/IUserInformation";
import api from "../../services/api";

export async function GetUserData(
  userId: string
): Promise<AxiosResponse<IUser>> {
  return await api.get<IUser>(`/user/id/${userId}`);
}

export async function GetUserInformation(
  userId: string
): Promise<AxiosResponse<IUserInformation>> {
  return await api.get<IUser>(`/user_information/id/${userId}`);
}

export interface ValideAccountProps {
  userId: string;
  key: string;
}

export async function ValidateAccount({
  key,
  userId,
}: ValideAccountProps): Promise<AxiosResponse<any>> {
  return await api.post(`/user/validate/${userId}/${key}`);
}

export async function GetAllUsersNumber(): Promise<AxiosResponse<IUser[]>> {
  return await api.get(`/user`);
}
