import { Avatar, Dropdown, Menu, message, Modal, Space, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import * as S from "./styles";
import { UserOutlined } from "@ant-design/icons";
import moment from "moment";
import { useTheme } from "styled-components";
import { Typography } from "antd";
import { CgOptions } from "react-icons/cg";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { GrFilter } from "react-icons/gr";
import { MdEmail, MdMessage } from "react-icons/md";
import { FaCentercode, FaUserTie } from "react-icons/fa";

import { AiFillPhone } from "react-icons/ai";
import { useAuth } from "../../../hooks/auth";
import { useResponsiveness } from "../../../hooks/Responsiveness";
import { TransparentButton } from "../../../styles/DefaultButton";
import {
  GetNewChatContactLinkByUserId,
  VariableElementFromUserType,
} from "../../../utils/functions";
import { GetUserData } from "../../../utils/RoutesFunctions/functions.Users";
import { IUser } from "../../../@types/IUser";
import { BiGridSmall } from "react-icons/bi";
import { DemandsProps } from "../../../utils/RoutesFunctions/funcion.demands";

const { Text } = Typography;
export type DemandObj = {
  id: string;
  title: string;
  description: string;
  avatarUrl: string;
  name: string;
  phone: string;
  date: any;
  createdBy: string;
  location: {
    city: string;
    cep: string;
    state: string;
  };
  details?: DetailsProps[];
  specialty: string;
  lawyerContactedFrom?: DemandsProps["contacts_from"];
  onDeleteDemand?(demandId: string): void;
};

type DetailsProps = {
  title: string;
  description: string;
  isFact: boolean;
};

type DemandSectionProps = {
  data: DemandObj;
};
const { confirm } = Modal;
const DemandSection: React.FC<DemandSectionProps> = ({ data }) => {
  const {
    avatarUrl,
    description,
    name,
    phone,
    title,
    date,
    details,
    lawyerContactedFrom,
    location,
    specialty,
    createdBy,
    id,
    onDeleteDemand,
  } = data;

  const handleContact = () => {
    const ContactLink = GetNewChatContactLinkByUserId({
      contact_user_id: createdBy,
      user,
      contactType: "newChat",
    });

    window.open(ContactLink, "_blank");
  };

  const { colors } = useTheme();
  const { ScreenMD } = useResponsiveness();
  const { user } = useAuth();
  const history = useHistory();
  function RedirectToChat() {
    const link = VariableElementFromUserType(user, {
      ToLawyer: "/escritorio/chat",
      ToUser: "/painel/chat",
    });
    history.push(link);
  }
  const [UserInd, SetUserInd] = useState({} as IUser);
  async function getUserInfo() {
    const userInf = (await GetUserData(createdBy)).data;
    SetUserInd(userInf);
  }

  useEffect(() => {
    getUserInfo();
  }, []);

  const AvatarRender = () => (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      <Avatar
        size="large"
        src={avatarUrl ? avatarUrl : <UserOutlined />}
        alt=""
      />
      <h5
        className="margin"
        style={{
          margin: "0 1rem",
        }}
      >
        {name}
      </h5>
    </div>
  );

  const DateRender = () => (
    <>
      <div
        style={{
          color: colors.lightText,
          fontSize: "0.8rem",
          marginRight: "1rem",
        }}
      >
        {moment(date).calendar()}
      </div>
    </>
  );

  const NumberOfLawyersContactedRender = () => (
    <>
      {lawyerContactedFrom && lawyerContactedFrom.length > 0 ? (
        <div onClick={RedirectToChat} style={{ cursor: "pointer" }}>
          <Text
            style={{
              color: colors.lightText,
              fontSize: "0.8rem",
              marginRight: "1rem",
            }}
          >
            {lawyerContactedFrom.length} contato
            {lawyerContactedFrom.length !== 1 ? "s" : ""} de advogado
            {lawyerContactedFrom.length !== 1 ? "s" : ""}
          </Text>
        </div>
      ) : (
        <div onClick={RedirectToChat} style={{ cursor: "pointer" }}>
          <Text
            style={{
              color: colors.lightText,
              fontSize: "0.8rem",
              marginRight: "1rem",
            }}
          >
            Encaminhado
          </Text>
        </div>
      )}
    </>
  );

  const LocationRender = () => (
    <div>
      <Text
        style={{
          color: colors.lightText,
          fontSize: "0.8rem",
          marginRight: "1rem",
        }}
      >
        {location.city}, {location.state}
      </Text>
    </div>
  );

  const SpecialtyRender = () => (
    <>
      {specialty !== "Unknown" && (
        <div>
          <Text
            style={{
              color: colors.lightText,
              fontSize: "0.8rem",
              marginRight: "1rem",
            }}
          >
            {specialty}
          </Text>
        </div>
      )}
    </>
  );

  const PhoneRender = () => (
    <>
      {phone !== "Unknown" && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <AiFillPhone size={15} style={{ marginRight: 5 }} />
          <Text
            style={{
              color: colors.lightText,
              fontSize: "0.8rem",
              marginRight: "1rem",
            }}
          >
            {phone}
          </Text>
        </div>
      )}
    </>
  );

  const EmailRender = () => (
    <>
      {UserInd && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <MdEmail size={15} style={{ marginRight: 5 }} />
          <Text
            style={{
              color: colors.lightText,
              fontSize: "0.8rem",
              marginRight: "1rem",
            }}
          >
            {UserInd.email}
          </Text>
        </div>
      )}
    </>
  );
  const IdRender = () => (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <BiGridSmall size={15} style={{ marginRight: 5 }} />
        <Text
          style={{
            color: colors.lightText,
            fontSize: "0.8rem",
            marginRight: "1rem",
          }}
        >
          {createdBy}
        </Text>
      </div>
    </>
  );
  const LawyerContactedRender = () => (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <BiGridSmall size={15} style={{ marginRight: 5 }} />
        <Text
          style={{
            color: colors.lightText,
            fontSize: "0.8rem",
            marginRight: "1rem",
          }}
        >
          {createdBy}
        </Text>
      </div>
    </>
  );
  console.log("Details", details);
  const AdditionalDetailsRender = () => (
    <>
      {details && (
        <S.DetailsArea>
          {details.map(({ description, title, isFact }) => (
            <Text>
              <Text strong>
                {title}
                {isFact ? "" : ":"}{" "}
              </Text>
              {description} <br />
            </Text>
          ))}
        </S.DetailsArea>
      )}
    </>
  );

  function showPromiseConfirmDeleteDemand() {
    confirm({
      title: "Tem certeza de que deseja excluir esta demanda?",
      icon: <ExclamationCircleOutlined />,
      content: "Está ação não poderá ser revertida.",
      onOk() {
        return new Promise((resolve, reject) => {
          onDeleteDemand && onDeleteDemand(id);
          resolve("");
        }).catch(() => console.log("Oops errors!"));
      },
      onCancel() {},
    });
  }
  const menuOptions = (
    <Menu>
      <Menu.Item onClick={showPromiseConfirmDeleteDemand}>
        <Text>Excluir Demanda</Text>
      </Menu.Item>
    </Menu>
  );

  function LawyerContactsCounterRender() {
    if (!lawyerContactedFrom) {
      return null;
    }
    return (
      <Tooltip placement="bottom" title="Advogados que fizeram contato">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginRight: 5,
          }}
        >
          <FaUserTie
            size={15}
            style={{
              marginRight: 3,
            }}
          />{" "}
          <Text
            style={{
              color: colors.lightText,
              fontSize: "0.8rem",
              marginRight: "1rem",
            }}
          >
            {lawyerContactedFrom.length}
          </Text>
        </div>
      </Tooltip>
    );
  }
  const RenderContactButton = () => (
    <TransparentButton onClick={handleContact}>
      <MdMessage size={15} style={{ marginRight: 5 }} /> Contatar
    </TransparentButton>
  );
  return (
    <S.Container>
      <S.DemandHeader>
        <S.DemandTitle>{title}</S.DemandTitle>
        <div
          style={{
            marginLeft: "auto",
            cursor: "pointer",
          }}
        >
          <Dropdown overlay={menuOptions} placement="bottomCenter">
            <CgOptions size={20} color={colors.lightGreyIcon} />
          </Dropdown>
        </div>
      </S.DemandHeader>

      <S.DemandDescription>
        {description}
        {AdditionalDetailsRender()}
      </S.DemandDescription>
      {ScreenMD && <div style={{ marginTop: "1.5rem" }}>{AvatarRender()}</div>}
      <S.FooterContent>
        {!ScreenMD && AvatarRender()}
        <Space wrap direction="horizontal">
          {" "}
          {SpecialtyRender()}
          {LocationRender()}
          {DateRender()}
          {PhoneRender()}
          {EmailRender()}
          {IdRender()}
          <div style={{ marginLeft: "auto" }}>
            {LawyerContactsCounterRender()}
          </div>
        </Space>
      </S.FooterContent>
      {ScreenMD && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "1.5rem",
          }}
        ></div>
      )}
    </S.Container>
  );
};

export default DemandSection;
