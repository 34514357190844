import { createGlobalStyle } from 'styled-components';

import defaultColorTheme from './defaultColorTheme';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box
  }
  overflow-x: hidden;
  // styles google places search menu
  .pac-container {
    background: ${props => props.theme.colors.whiteOrDark};
    padding: 0.5rem 1rem 1rem 1rem;
    font: 16px Ubuntu, sans-serif !important;
    border-radius: 0 0 1.2rem 1.2rem;
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.1);

  }
  .gmnoprint {
      position: absolute;
      top: unset;
      bottom: 1rem !important;

    }

  scroll-behavior: smooth;
  overflow-y: scroll;
  body {

    background: ${defaultColorTheme.background};
    -webkit-font-smoothing: antialiased;

  }
  body, input, button {
    font: 16px Ubuntu, sans-serif;
  }
  button {
    cursor: pointer;

  }
  p {
    line-height: 1.35rem;
    color: ${props => props.theme.colors.text};
    font-size: 0.9rem;

  }
  h1, h2, h3 {
    
    margin: 0rem;
    padding: 0;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn  {
    color: black !important;
  }
  .ant-image-preview-wrap, .ant-image-preview-mask,  .ant-image-preview-operations, .ant-image-preview-content, .ant-image-preview {
    z-index: 100001 !important;
  }
  .ant-btn-primary {
    color: ${props => props.theme.colors.title} !important;
  }




  h1, h2, h3, h4, h5 {
    font: 16px Ubuntu, sans-serif;
    color: ${props => props.theme.colors.title};
    margin: 0rem;
    padding: 0;
  }


  ::-webkit-scrollbar {

    background: rgba(255, 255, 255, 0);
  width: 12px;               /* width of the entire scrollbar */
}

::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0);        /* color of the tracking area */
}

::-webkit-scrollbar-thumb {
  width: 8px;
  background-color: rgba(73, 73, 73, 0.25);    /* color of the scroll thumb */
  border-radius: 5px;       /* roundness of the scroll thumb */
  border: 3px solid rgba(73, 73, 73, 0);
  transition: background-color 0.3s;
  &:hover {
    background-color: rgba(73, 73, 73, 0.35);    /* color of the scroll thumb */

  }  /* creates padding around scroll thumb */


}

  @media (max-width: 768px) {

    ::-webkit-scrollbar {

background: rgba(255, 255, 255, 0);
width: 0px;               /* width of the entire scrollbar */
}

::-webkit-scrollbar-track {
background: rgba(255, 255, 255, 0);        /* color of the tracking area */
}

::-webkit-scrollbar-thumb {
width: 0px;
background-color: rgba(73, 73, 73, 0);    /* color of the scroll thumb */
border-radius: 5px;       /* roundness of the scroll thumb */
border: 3px solid rgba(73, 73, 73, 0);
transition: background-color 0.3s;
&:hover {
background-color: rgba(73, 73, 73, 0);    /* color of the scroll thumb */

}  /* creates padding around scroll thumb */


}
}
`;
