import styled from "styled-components";
import { DefaultStyledArea } from "../../styles/DefaultStyledArea";

export const Container = styled.div`
  ${DefaultStyledArea.defaultPadding}
  overflow-x: hidden;
  position: relative;
`;
export const MainTitle = styled.div`
  position: relative;
  padding-top: 2rem;
  padding-bottom: 1rem;
  h1,
  p {
    text-align: center;
  }
`;
