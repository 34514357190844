import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import React from "react";
import { useTheme } from "styled-components";
interface props {
  loading: boolean;
  fullPage?: boolean;
  empty?: boolean;
}

const Loading: React.FC<props> = ({ children, loading, fullPage, empty }) => {
  const { colors } = useTheme();
  const antIcon = <LoadingOutlined style={{ fontSize: 40 }} spin />;
  const fullPageIcon = (
    <LoadingOutlined
      style={{ fontSize: 40, position: "fixed" }}
      spin
      color={colors.primary}
    />
  );
  const emptyAlone = <></>;
  return (
    <>
      <Spin
        indicator={fullPage ? fullPageIcon : empty ? emptyAlone : antIcon}
        spinning={loading}
        delay={500}
      >
        {children}
      </Spin>
    </>
  );
};

export default Loading;
