import { Layout, Menu } from "antd";
import React, { useEffect, useState } from "react";
import { useRouteMatch } from "react-router-dom";
import PageHeaderCP from "../PageHeader/PageHeader";
import SideMenu from "../SideMenu/SideMenu";
import * as S from "./styled.PageLayout";
const { SubMenu } = Menu;
const { Header, Content, Footer, Sider } = Layout;

const PageLayout: React.FC = ({ children }) => {
  const [SideMenuCollapsedState, SetSideMenuCollapsedState] = useState(true);
  const onToggleCollapse = (collapsed: boolean) => {
    SetSideMenuCollapsedState(collapsed);
  };

  const [MenuKeys, SetMenuKeys] = useState([""]);
  const { url } = useRouteMatch();

  function SelectedMenuKeys(PageName: string) {
    if (PageName === "dashboard") {
      SetMenuKeys(["dashboard"]);
    }
    if (PageName === "lawyers-list") {
      SetMenuKeys(["lawyers", "list"]);
    }
  }

  useEffect(() => {
    const namePage = url.split("/").find((item) => item !== "") || "";

    SelectedMenuKeys(namePage);
  }, [url]);
  return (
    <S.Container>
      <Layout style={{ minHeight: "100vh" }}>
        <Sider
          collapsible
          collapsed={SideMenuCollapsedState}
          onCollapse={onToggleCollapse}
        >
          <SideMenu />
        </Sider>
        <Layout className="site-layout">
          <Header className="site-layout-background" style={{ padding: 0 }}>
            <PageHeaderCP />
          </Header>

          <Content style={{ margin: "1rem 0 0 0" }}>{children}</Content>
          <Footer style={{ textAlign: "center" }}>Direito Prático</Footer>
        </Layout>
      </Layout>
    </S.Container>
  );
};

export default PageLayout;
