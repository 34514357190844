import api from "../../services/api";
interface additionalDetailsProps {
  title: string;
  description: string;
  isFact: boolean;
}
export interface DemandsProps {
  id?: string;

  created_by: string;

  title: string;

  description: string;

  name: {
    first_name: string;
    last_name: string;
  };

  phone: string;

  contacts_from: { id: string }[];

  specialty: string[];

  responses?: any[];

  tags?: string[];

  location: {
    cep: string;
    city: string;
    state: string;
  };

  additionalDetails?: additionalDetailsProps[];

  created_at?: string;

  updated_at?: string;
}

export function CreateDemand(demandObj: DemandsProps) {
  return api.post<DemandsProps[]>(`/demands`, demandObj);
}

export function GetDemandsByUserId(userId: string) {
  return api.get<DemandsProps[]>(`/demands/user/${userId}`);
}

export function EditDemand(demand: DemandsProps) {
  return api.post<DemandsProps[]>(`/demands`, demand);
}

export interface ListDemandsProps {
  page: number;
  filters?: {
    specialties: string[];
    locations: string[];
  };
}

export function ListDemands({ page, filters }: ListDemandsProps) {
  let skip = 0;
  let take = 15;

  if (page > 0) {
    skip = page * take;
  }

  const ApiData = {
    skip: String(skip),
    take: String(take),
    filters,
  };
  return api.post<DemandsProps[]>(`/demands/search/t1`, ApiData);
}

export function DeleteDemand(eventId: string) {
  return api.delete<any>(`/demands/id/${eventId}`);
}
