import * as cities from './AllCities';
import AllStates from './AllStates';

const AllCitiesAndStates = [
  { label: 'Acre', options: cities.AC_Options },
  { label: 'Alagoas', options: cities.AL_Options },
  { label: 'Amazonas', options: cities.AM_Options },
  { label: 'Amapá', options: cities.AP_Options },
  { label: 'Bahia', options: cities.BA_Options },
  { label: 'Ceará', options: cities.CE_Options },
  { label: 'Distrito Federal', options: cities.DF_Options },
  { label: 'Espírito Santo', options: cities.ES_Options },
  { label: 'Goiás', options: cities.GO_Options },
  { label: 'Maranhão', options: cities.MA_Options },
  { label: 'Minas Gerais', options: cities.MG_Options },
  { label: 'Mato Grosso do Sul', options: cities.MS_Options },
  { label: 'Mato Grosso', options: cities.MT_Options },
  { label: 'Pará', options: cities.PA_Options },
  { label: 'Paraíba', options: cities.PB_Options },
  { label: 'Pernambuco', options: cities.PE_Options },
  { label: 'Piauí', options: cities.PI_Options },
  { label: 'Paraná', options: cities.PR_Options },
  { label: 'Rio de Janeiro', options: cities.RJ_Options },
  { label: 'Rio Grande do Norte', options: cities.RN_Options },
  { label: 'Rondônia', options: cities.RO_Options },
  { label: 'Roraima', options: cities.RR_Options },
  { label: 'Rio Grande do Sul', options: cities.RS_Options },
  { label: 'Santa Catarina', options: cities.SC_Options },
  { label: 'Sergipe', options: cities.SE_Options },
  { label: 'São Paulo', options: cities.SP_Options },
  { label: 'Tocantins', options: cities.TO_Options },
  { label: 'Estados', options: AllStates }
];

export default AllCitiesAndStates;
