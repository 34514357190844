export function ParticipantVideoMeetingStartingEmail(
  name: string,
  adminName: string,
  link: string
) {
  return `
 <!doctype html>
<html>
  <head>
  <link href="https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;700&display=swap" rel="stylesheet">
    <meta name="viewport" content="width=device-width">
    <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
    <title>O Anfitrião Iniciou a Reunião</title>
  <style>
@media only screen and (max-width: 620px) {
  table[class=body] h1 {
    font-size: 28px !important;
    margin-bottom: 10px !important;
  }

  table[class=body] p,
table[class=body] ul,
table[class=body] ol,
table[class=body] td,
table[class=body] span,
table[class=body] a {
    font-size: 16px !important;
  }

  table[class=body] .wrapper,
table[class=body] .article {
    padding: 10px !important;
  }

  table[class=body] .content {
    padding: 0 !important;
  }

  table[class=body] .container {
    padding: 0 !important;
    width: 100% !important;
  }

  table[class=body] .main {
    border-left-width: 0 !important;
    border-radius: 16px;
    !important;
    padding: 8px !important;
    border-right-width: 0 !important;
  }

  table[class=body] .btn table {
    width: 100% !important;
  }

  table[class=body] .btn a {
    width: 100% !important;
  }

  table[class=body] .img-responsive {
    height: auto !important;
    max-width: 100% !important;
    width: auto !important;
  }
}
@media all {
  .ExternalClass {
    width: 100%;
  }

  .ExternalClass,
.ExternalClass p,
.ExternalClass span,
.ExternalClass font,
.ExternalClass td,
.ExternalClass div {
    line-height: 100%;
  }

  .apple-link a {
    color: inherit !important;
    font-family: inherit !important;
    font-size: inherit !important;
    font-weight: inherit !important;
    line-height: inherit !important;
    text-decoration: none !important;
  }

  .btn-primary table td:hover {
    background-color: #e3b812 !important;
  }

  .btn-primary a:hover {
    background-color: #ffffff !important;
    border-color: #e3b812 !important;
  }
}
</style></head>
  <body class style="background-color: #f9f9f9; font-family: Ubuntu, sans-serif; -webkit-font-smoothing: antialiased; font-size: 14px; line-height: 1.4; margin: 0; padding: 0; -ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%;">
    <table role="presentation" border="0" cellpadding="0" cellspacing="0" class="body" style="border-collapse: separate; mso-table-lspace: 0pt; mso-table-rspace: 0pt; min-width: 100%; background-color: #f9f9f9; width: 100%;" width="100%" bgcolor="#f9f9f9">
      <tr>
        <td style="font-family: Ubuntu, sans-serif; font-size: 14px; vertical-align: top;" valign="top">&nbsp;</td>
        <td class="container" style="font-family: Ubuntu, sans-serif; font-size: 14px; vertical-align: top; display: block; max-width: 580px; padding: 10px; width: 580px; Margin: 0 auto;" width="580" valign="top">
          <div class="header" style="padding: 20px 0;">
            <table role="presentation" border="0" cellpadding="0" cellspacing="0" style="border-collapse: separate; mso-table-lspace: 0pt; mso-table-rspace: 0pt; min-width: 100%; width: 100%;" width="100%">
              <tr>
                <td class="align-center" style="font-family: Ubuntu, sans-serif; font-size: 14px; vertical-align: top; text-align: center;" valign="top" align="center">
                  <a href="https://direitopratico.com.br" style="color: #f0c419; text-decoration: underline;"><img src="https://direito-pratico-app.s3.amazonaws.com/app/logo_dp_black.png" height="60" alt="Postdrop" style="border: none; -ms-interpolation-mode: bicubic; max-width: 100%;"></a>
                </td>
              </tr>
            </table>
          </div>
          <div class="content" style="box-sizing: border-box; display: block; Margin: 0 auto; max-width: 580px; padding: 10px;">

            <!-- START CENTERED WHITE CONTAINER -->
            <span class="preheader" style="color: transparent; display: none; height: 0; max-height: 0; max-width: 0; opacity: 0; overflow: hidden; mso-hide: all; visibility: hidden; width: 0;">O Anfitrião Iniciou a Reunião</span>
            <table role="presentation" class="main" style="border-collapse: separate; mso-table-lspace: 0pt; mso-table-rspace: 0pt; min-width: 100%; background: #ffffff; box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.14); border-radius: 16px; width: 100%; transition: all 0.2s ease-in-out;" width="100%">

              <!-- START MAIN CONTENT AREA -->

              <tr>
                <td class="wrapper" style="font-family: Ubuntu, sans-serif; font-size: 14px; vertical-align: top; box-sizing: border-box; padding: 20px;" valign="top">
                  <table role="presentation" border="0" cellpadding="0" cellspacing="0" style="border-collapse: separate; mso-table-lspace: 0pt; mso-table-rspace: 0pt; min-width: 100%; width: 100%;" width="100%">
                    <tr>
                      <td style="font-family: Ubuntu, sans-serif; font-size: 14px; vertical-align: top;" valign="top">
                        <h1 style="color: #06090f; font-family: Ubuntu, sans-serif; line-height: 1.4; margin: 0; margin-bottom: 30px; font-size: 35px; font-weight: 500; text-align: center; text-transform: capitalize;">O Anfitrião Iniciou a Reunião</h1>
                        <p style="font-family: Ubuntu, sans-serif; font-size: 14px; font-weight: normal; margin: 0; margin-bottom: 15px;">Olá ${name}!</p>
                        <p style="font-family: Ubuntu, sans-serif; font-size: 14px; font-weight: normal; margin: 0; margin-bottom: 15px;">A reunião já começou, seu anfitrião o aguarda na sala de conferência da Direito Prático.</p>
                        <p style="font-family: Ubuntu, sans-serif; font-size: 14px; font-weight: normal; margin: 0; margin-bottom: 15px;">Para acessar a sala, clique no botão abaixo "Iniciar Reunião" ou acesse seu escritório digtal com seu login e senha.</p>
                        <table role="presentation" border="0" cellpadding="0" cellspacing="0" class="center" style="border-collapse: separate; mso-table-lspace: 0pt; mso-table-rspace: 0pt; min-width: 100%; width: 100%;" width="100%">
                          <tbody>
                            <tr>
                              <td align="center" style="font-family: Ubuntu, sans-serif; font-size: 14px; vertical-align: top;" valign="top">
                                <table role="presentation" border="0" cellpadding="0" cellspacing="0" style="border-collapse: separate; mso-table-lspace: 0pt; mso-table-rspace: 0pt; min-width: 100%; width: 100%;" width="100%">
                                  <tbody>
                                    <tr>
                                        <p class="apple-link" style="font-family: Ubuntu, sans-serif; font-size: 14px; font-weight: normal; margin: 0; margin-bottom: 15px;">Anfitrião:</p>
                                      <!-- <td align="center"> <img class='avatar padding' src="https://blog.influx.com.br/storage/app/media/uploaded-files/00-2019/Ajustes/08-Ago19/Primeira%20Quinzena/100819-people_person/IMG/people-person1.jpg" />  -->
                                      <td align="center" style="font-family: Ubuntu, sans-serif; font-size: 14px; vertical-align: top;" valign="top"> <img class="avatar padding" src="${`https://ui-avatars.com/api/?name=${adminName}`}" style="border: none; -ms-interpolation-mode: bicubic; border-radius: 50%; max-width: 250px;">
                                      <h1 style="color: #06090f; font-family: Ubuntu, sans-serif; line-height: 1.4; margin: 0; margin-bottom: 30px; font-size: 35px; font-weight: 500; text-align: center; text-transform: capitalize;">${adminName}</h1>
                                      <!-- <h1>Nome da pessoa</h1> -->
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <table role="presentation" border="0" cellpadding="0" cellspacing="0" class="btn btn-primary" style="border-collapse: separate; mso-table-lspace: 0pt; mso-table-rspace: 0pt; transition: all 0.2s ease-in-out; box-sizing: border-box; min-width: 100%; width: 100%;" width="100%">
                          <tbody>
                            <tr>
                              <td align="center" style="font-family: Ubuntu, sans-serif; font-size: 14px; vertical-align: top; padding-bottom: 15px;" valign="top">
                                <table role="presentation" border="0" cellpadding="0" cellspacing="0" style="border-collapse: separate; mso-table-lspace: 0pt; mso-table-rspace: 0pt; min-width: auto; width: auto;">
                                  <tbody>
                                    <tr>
                                      <td align="center" style="font-family: Ubuntu, sans-serif; font-size: 14px; vertical-align: top; border-radius: 16px; text-align: center; background-color: #f0c419;" valign="top" bgcolor="#f0c419">

                                        <a href="${link}" target="_blank" style="transition: all 0.2s ease-in-out; border: solid 2px #f0c419; border-radius: 16px; box-sizing: border-box; cursor: pointer; display: inline-block; font-size: 14px; font-weight: bold; margin: 0; padding: 12px 25px; text-decoration: none; text-transform: capitalize; background-color: #f0c419; border-color: #f0c419; color: #282829;">Entrar na Reunião</a> </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>

                        <p style="font-family: Ubuntu, sans-serif; font-size: 14px; font-weight: normal; margin: 0; margin-bottom: 15px;">Caso tenha alguma dúvida ou precise de nosso suporte, entre em contato conosco através do e-mail, contato@direitopratico.com.br.</p>
                        <p style="font-family: Ubuntu, sans-serif; font-size: 14px; font-weight: normal; margin: 0; margin-bottom: 15px;">Estamos sempre à disposição.</p>
                        <p style="font-family: Ubuntu, sans-serif; font-size: 14px; font-weight: normal; margin: 0; margin-bottom: 15px;">Atenciosamente equipe Direito Prático</p><p style="font-family: Ubuntu, sans-serif; font-size: 14px; font-weight: normal; margin: 0; margin-bottom: 15px;">
                      </p></td>
                    </tr>
                  </table>
                </td>
              </tr>

            <!-- END MAIN CONTENT AREA -->
            </table>

            <!-- START FOOTER -->
            <div class="footer" style="clear: both; Margin-top: 10px; text-align: center; width: 100%;">
              <table role="presentation" border="0" cellpadding="0" cellspacing="0" style="border-collapse: separate; mso-table-lspace: 0pt; mso-table-rspace: 0pt; min-width: 100%; width: 100%;" width="100%">
                <tr>
                  <td class="content-block" style="font-family: Ubuntu, sans-serif; vertical-align: top; padding-bottom: 10px; padding-top: 10px; color: #9a9ea6; font-size: 12px; text-align: center;" valign="top" align="center">
                    <span class="apple-link" style="color: #9a9ea6; font-size: 12px; text-align: center;">Copyright © 2017 - 2021 - Todos os Direito Reservados</span>

                  </td>
                </tr>
                <tr>
                  <td class="content-block powered-by" style="font-family: Ubuntu, sans-serif; vertical-align: top; padding-bottom: 10px; padding-top: 10px; color: #9a9ea6; font-size: 12px; text-align: center;" valign="top" align="center">
                    contato@direitopratico.com.br
                  </td>
                </tr>
              </table>
            </div>
            <!-- END FOOTER -->

          <!-- END CENTERED WHITE CONTAINER -->
          </div>
        </td>
        <td style="font-family: Ubuntu, sans-serif; font-size: 14px; vertical-align: top;" valign="top">&nbsp;</td>
      </tr>
    </table>
  </body>
</html>

  `;
}
