import dotenv from 'dotenv';

dotenv.config();

const baseURL = {
  baseURL: process.env.REACT_APP_BACKEND_URL,
  files: process.env.REACT_APP_FILES_URL
};

console.log(baseURL);
const Backend = {
  baseURL: `${baseURL.baseURL}`,
  files: `${baseURL.files}`
};

export default Backend;
