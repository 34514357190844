import {
  PieChartOutlined,
  TeamOutlined,
  IssuesCloseOutlined,
  VideoCameraAddOutlined,
} from "@ant-design/icons";
import { Menu, Layout } from "antd";

import React, { useEffect, useState } from "react";
import { FaUserFriends } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { RiEmpathizeLine } from "react-icons/ri";
import { useRouteMatch } from "react-router";
import { useHistory } from "react-router-dom";
const { Sider } = Layout;
const { SubMenu } = Menu;

const SideMenu: React.FC = () => {
  const [SideMenuCollapsedState, SetSideMenuCollapsedState] = useState(true);
  const onToggleCollapse = (collapsed: boolean) => {
    SetSideMenuCollapsedState(collapsed);
  };

  const [MenuKeys, SetMenuKeys] = useState([""]);
  const { url } = useRouteMatch();

  function SelectedMenuKeys(PageName: string) {
    if (PageName === "dashboard") {
      SetMenuKeys(["dashboard"]);
    }
    if (PageName === "lawyers-list") {
      SetMenuKeys(["lawyers", "list"]);
    }
    if (PageName === "email") {
      SetMenuKeys(["email"]);
    }
    if (PageName === "demandas") {
      SetMenuKeys(["demandas"]);
    }
    if (PageName === "meeting") {
      SetMenuKeys(["meeting"]);
    }
  }

  useEffect(() => {
    const namePage = url.split("/").find((item) => item !== "") || "";

    SelectedMenuKeys(namePage);
  }, [url]);

  const history = useHistory();

  return (
    <>
      <div className="logo" />
      <Menu theme="dark" defaultSelectedKeys={MenuKeys} mode="inline">
        <Menu.Item
          key="dashboard"
          onClick={() => history.push("/dashboard")}
          icon={<PieChartOutlined />}
        >
          Dashboard
        </Menu.Item>

        <Menu.Item
          key="demandas"
          onClick={() => history.push("/demandas")}
          icon={<IssuesCloseOutlined />}
        >
          Demandas
        </Menu.Item>

        <SubMenu key="lawyers" icon={<TeamOutlined />} title="Advogados">
          <Menu.Item key="list" onClick={() => history.push("/lawyers-list")}>
            Lista de Advogados
          </Menu.Item>
          {/* <Menu.Item key="8">Team 2</Menu.Item> */}
        </SubMenu>
        {/* <SubMenu key="sub1" icon={<UserOutlined />} title="User">
            <Menu.Item key="3">Tom</Menu.Item>
            <Menu.Item key="4">Bill</Menu.Item>
            <Menu.Item key="5">Alex</Menu.Item>
          </SubMenu>
          <Menu.Item key="9" icon={<FileOutlined />}>
            Files
          </Menu.Item> */}
        <Menu.Item
          key="users"
          onClick={() => history.push("/users-list")}
          icon={<FaUserFriends color={"white"} />}
        >
          Lista de Usuários
        </Menu.Item>
        <Menu.Item
          key="email"
          onClick={() => history.push("/email")}
          icon={<MdEmail />}
        >
          Email
        </Menu.Item>
        <Menu.Item
          key="meeting"
          onClick={() => history.push("/meeting")}
          icon={<VideoCameraAddOutlined />}
        >
          Reunião
        </Menu.Item>
      </Menu>
    </>
  );
};

export default SideMenu;
