// const allOccupationAreasOptions = [
//   { value: 'Aduaneiro', label: 'Aduaneiro' },
//   { value: 'Administrativo', label: 'Administrativo' },
//   { value: 'Agricultura', label: 'Agricultura' },
//   { value: 'Alimentação', label: 'Alimentação' },
//   { value: 'Automotivo', label: 'Automotivo' },
//   { value: 'Aviação', label: 'Aviação' },
//   { value: 'Bancário', label: 'Bancário' },
//   { value: 'Marítimo e Portuário', label: 'Marítimo e Portuário' },
//   {
//     value: 'Ciência da Vida e Biotecnologia',
//     label: 'Ciência da Vida e Biotecnologia'
//   },
//   { value: 'Construção', label: 'Construção' },
//   { value: 'Corporativo e Incorporação', label: 'Corporativo e Incorporação' },
//   { value: 'Comercio Internacional', label: 'Comercio Internacional' },
//   { value: 'Criptoativos e Blockchain', label: 'Criptoativos e Blockchain' },
//   {
//     value: 'Cibersegurança e Privacidade de Dados',
//     label: 'Cibersegurança e Privacidade de Dados'
//   },
//   { value: 'Defesa e Aeroespacial', label: 'Defesa e Aeroespacial' },
//   { value: 'Digital', label: 'Digital' },
//   { value: 'Educação', label: 'Educação' },
//   { value: 'Entretenimento e Lazer', label: 'Entretenimento e Lazer' },
//   { value: 'Esportes', label: 'Esportes' },
//   { value: 'Bioenergia e Energia', label: 'Bioenergia e Energia' },
//   { value: 'Farmacêutico', label: 'Farmacêutico' },
//   { value: 'Franquia', label: 'Franquia' },
//   { value: 'Hotéis e Resorts', label: 'Hotéis e Resorts' },
//   { value: 'Infraestrutura', label: 'Infraestrutura' },
//   { value: 'Investigações', label: 'Investigações' },
//   { value: 'Internet', label: 'Internet' },
//   { value: 'Importação e Exportação', label: 'Importação e Exportação' },
//   { value: 'Jogos', label: 'Jogos' },
//   { value: 'Licenciamento', label: 'Licenciamento' },
//   { value: 'Mercado de Luxo', label: 'Mercado de Luxo' },
//   { value: 'Meio Ambiente', label: 'Meio Ambiente' },
//   { value: 'Mineração', label: 'Mineração' },
//   { value: 'Moda', label: 'Moda' },
//   { value: 'Mudança do Clima', label: 'Mudança do Clima' },
//   { value: 'Negócios de Impacto', label: 'Negócios de Impacto' },
//   {
//     value: 'Saúde e Segurança Ocupacional',
//     label: 'Saúde e Segurança Ocupacional'
//   },
//   { value: 'Sanitário', label: 'Sanitário' },
//   { value: 'Saneamento', label: 'Saneamento' },
//   { value: 'Serviços Públicos', label: 'Serviços Públicos' },
//   { value: 'Seguros e Resseguros', label: 'Seguros e Resseguros' },
//   { value: 'Sustentabilidade', label: 'Sustentabilidade' },
//   { value: 'Startups', label: 'Startups' },
//   { value: 'Licenciamento', label: 'Licenciamento' },
//   { value: 'Parcerias', label: 'Parcerias' },
//   { value: 'Papel e Celulose', label: 'Papel e Celulose' },
//   {
//     value: 'Petróleo, Gás e Biocombustíveis',
//     label: 'Petróleo, Gás e Biocombustíveis'
//   },
//   { value: 'Química e Petroquímica', label: 'Química e Petroquímica' },
//   { value: 'Relações Internacionais', label: 'Relações Internacionais' },
//   { value: 'Representação Comercial', label: 'Representação Comercial' },
//   { value: 'Regulatório', label: 'Regulatório' },
//   { value: 'Recursos Naturais', label: 'Recursos Naturais' },
//   { value: 'Varejo e Consumo', label: 'Varejo e Consumo' },
//   { value: 'Tecnologia e Inovação', label: 'Tecnologia e Inovação' },
//   { value: 'Tecnologia da Informação', label: 'Tecnologia da Informação' },
//   { value: 'Transporte e Logística', label: 'Transporte e Logística' }
// ];

import allSpecialtiesOptions from './allSpecialties';

const markets = [
  { value: 'Aduaneiro', label: 'Aduaneiro' },
  { value: 'Administrativo', label: 'Administrativo' },
  { value: 'Agricultura', label: 'Agricultura' },
  { value: 'Alimentação', label: 'Alimentação' },
  { value: 'Automotivo', label: 'Automotivo' },
  { value: 'Aviação', label: 'Aviação' },
  { value: 'Bancário', label: 'Bancário' },
  { value: 'Marítimo e Portuário', label: 'Marítimo e Portuário' },
  {
    value: 'Ciência da Vida e Biotecnologia',
    label: 'Ciência da Vida e Biotecnologia'
  },
  { value: 'Construção', label: 'Construção' },
  { value: 'Corporativo e Incorporação', label: 'Corporativo e Incorporação' },
  { value: 'Comercio Internacional', label: 'Comercio Internacional' },
  { value: 'Criptoativos e Blockchain', label: 'Criptoativos e Blockchain' },
  {
    value: 'Cibersegurança e Privacidade de Dados',
    label: 'Cibersegurança e Privacidade de Dados'
  },
  { value: 'Defesa e Aeroespacial', label: 'Defesa e Aeroespacial' },
  { value: 'Digital', label: 'Digital' },
  { value: 'Educação', label: 'Educação' },
  { value: 'Entretenimento e Lazer', label: 'Entretenimento e Lazer' },
  { value: 'Esportes', label: 'Esportes' },
  { value: 'Bioenergia e Energia', label: 'Bioenergia e Energia' },
  { value: 'Farmacêutico', label: 'Farmacêutico' },
  { value: 'Franquia', label: 'Franquia' },
  { value: 'Hotéis e Resorts', label: 'Hotéis e Resorts' },
  { value: 'Infraestrutura', label: 'Infraestrutura' },
  { value: 'Investigações', label: 'Investigações' },
  { value: 'Internet', label: 'Internet' },
  { value: 'Importação e Exportação', label: 'Importação e Exportação' },
  { value: 'Jogos', label: 'Jogos' },
  { value: 'Licenciamento', label: 'Licenciamento' },
  { value: 'Mercado de Luxo', label: 'Mercado de Luxo' },
  { value: 'Meio Ambiente', label: 'Meio Ambiente' },
  { value: 'Mineração', label: 'Mineração' },
  { value: 'Moda', label: 'Moda' },
  { value: 'Mudança do Clima', label: 'Mudança do Clima' },
  { value: 'Negócios de Impacto', label: 'Negócios de Impacto' },
  {
    value: 'Saúde e Segurança Ocupacional',
    label: 'Saúde e Segurança Ocupacional'
  },
  { value: 'Sanitário', label: 'Sanitário' },
  { value: 'Saneamento', label: 'Saneamento' },
  { value: 'Serviços Públicos', label: 'Serviços Públicos' },
  { value: 'Seguros e Resseguros', label: 'Seguros e Resseguros' },
  { value: 'Sustentabilidade', label: 'Sustentabilidade' },
  { value: 'Startups', label: 'Startups' },
  { value: 'Licenciamento', label: 'Licenciamento' },
  { value: 'Parcerias', label: 'Parcerias' },
  { value: 'Papel e Celulose', label: 'Papel e Celulose' },
  {
    value: 'Petróleo, Gás e Biocombustíveis',
    label: 'Petróleo, Gás e Biocombustíveis'
  },
  { value: 'Química e Petroquímica', label: 'Química e Petroquímica' },
  { value: 'Relações Internacionais', label: 'Relações Internacionais' },
  { value: 'Representação Comercial', label: 'Representação Comercial' },
  { value: 'Regulatório', label: 'Regulatório' },
  { value: 'Recursos Naturais', label: 'Recursos Naturais' },
  { value: 'Varejo e Consumo', label: 'Varejo e Consumo' },
  { value: 'Tecnologia e Inovação', label: 'Tecnologia e Inovação' },
  { value: 'Tecnologia da Informação', label: 'Tecnologia da Informação' },
  { value: 'Transporte e Logística', label: 'Transporte e Logística' }
];

const management = [
  { value: 'Antitruste', label: 'Antitruste' },
  { value: 'Concorrencial', label: 'Concorrencial' },
  { value: 'Contencioso', label: 'Contencioso' },
  { value: 'Contratos', label: 'Contratos' },
  { value: 'Contratos Comerciais', label: 'Contratos Comerciais' },
  { value: 'Contratos Corporativos', label: 'Contratos Corporativos' },
  { value: 'Contratos Governamentais', label: 'Contratos Governamentais' },
  { value: 'Contratos Imobiliários', label: 'Contratos Imobiliários' },
  { value: 'Contratos Internacionais', label: 'Contratos Internacionais' },
  {
    value: 'Contrato Social e Societário',
    label: 'Contrato Social e Societário'
  },
  { value: 'Contratos Trabalhistas', label: 'Contratos Trabalhistas' },
  { value: 'Compliance', label: 'Compliance' },
  {
    value: 'Constituição de Empresa Offshore',
    label: 'Constituição de Empresa Offshore'
  },
  { value: 'Due Diligence', label: 'Due Diligence' },
  { value: 'Gerenciamento de Crise', label: 'Gerenciamento de Crise' },
  { value: 'Governança', label: 'Governança' },
  {
    value: 'Internacionalização de Empresa',
    label: 'Internacionalização de Empresa'
  },
  { value: 'Inventário', label: 'Inventário' },
  { value: 'LGPD', label: 'LGPD' },
  { value: 'Pareceres', label: 'Pareceres' },
  { value: 'Sociedades', label: 'Sociedades' },
  { value: 'Tributária', label: 'Tributária' }
];

const bankruptcyAndDebt = [
  { value: 'Acordos de Dívidas', label: 'Acordos de Dívidas' },
  {
    value: 'Cobrança de Dívidas Extra e Judicial',
    label: 'Cobrança de Dívidas Extra e Judicial'
  },
  { value: 'Execução Fiscal', label: 'Execução Fiscal' },
  {
    value: 'Falência e Recuperação Judicial',
    label: 'Falência e Recuperação Judicial'
  },
  { value: 'Liquidação de Dívidas', label: 'Liquidação de Dívidas' },
  { value: 'Negativação SPC/SERASA', label: 'Negativação SPC/SERASA' },
  { value: 'Reparação de Créditos', label: 'Reparação de Créditos' },
  { value: 'Reestruturação de Empresas', label: 'Reestruturação de Empresas' }
];

const communicationAndMarketing = [
  { value: 'Concessões', label: 'Concessões' },
  { value: 'Mídias Sociais', label: 'Mídias Sociais' },
  { value: 'Publicidade', label: 'Publicidade' },
  { value: 'Propaganda Eleitoral', label: 'Propaganda Eleitoral' },
  { value: 'Telecomunicações', label: 'Telecomunicações' }
];

const finances = [
  { value: 'BNDES', label: 'BNDES' },
  {
    value: 'Capital de Risco e Investimento Anjo',
    label: 'Capital de Risco e Investimento Anjo'
  },
  { value: 'Cartão de Crédito', label: 'Cartão de Crédito' },
  { value: 'Companhias Abertas', label: 'Companhias Abertas' },
  { value: 'Consórcio', label: 'Consórcio' },
  { value: 'Derivativos', label: 'Derivativos' },
  { value: 'Dívida Ativa', label: 'Dívida Ativa' },
  { value: 'Empréstimo', label: 'Empréstimo' },
  { value: 'FIES', label: 'FIES' },
  { value: 'Financiamento Imobiliário', label: 'Financiamento Imobiliário' },
  {
    value: 'Financiamentos e Leasing de Equipamentos',
    label: 'Financiamentos e Leasing de Equipamentos'
  },
  { value: 'Financiamento de Projeto', label: 'Financiamento de Projeto' },
  { value: 'Finanças Públicas', label: 'Finanças Públicas' },
  { value: 'Fusões e Aquisições', label: 'Fusões e Aquisições' },
  { value: 'Inovação Financeira', label: 'Inovação Financeira' },
  { value: 'Investimento Estrangeiro', label: 'Investimento Estrangeiro' },
  { value: 'Investimento Florestal', label: 'Investimento Florestal' },
  { value: 'Investimento Sustentável', label: 'Investimento Sustentável' },
  { value: 'Impostos', label: 'Impostos' },
  { value: 'IPVA', label: 'IPVA' },
  { value: 'IPTU', label: 'IPTU' },
  { value: 'Meios de Pagamento', label: 'Meios de Pagamento' },
  { value: 'Mercado de Capitais', label: 'Mercado de Capitais' },
  {
    value: 'Ofertas de Títulos Públicos (IPO)',
    label: 'Ofertas de Títulos Públicos (IPO)'
  },
  {
    value: 'Private Equity e Venture Capital',
    label: 'Private Equity e Venture Capital'
  },
  { value: 'Repatriação de Capital', label: 'Repatriação de Capital' },
  {
    value: 'Revisionais de Contrato Bancário',
    label: 'Revisionais de Contrato Bancário'
  }
];

const civis = [
  { value: 'Aposentadoria Especial', label: 'Aposentadoria Especial' },
  { value: 'Aposentadoria Híbrida', label: 'Aposentadoria Híbrida' },
  { value: 'Aposentadoria por Idade', label: 'Aposentadoria por Idade' },
  {
    value: 'Aposentadoria por Invalidez',
    label: 'Aposentadoria por Invalidez'
  },
  { value: 'Aposentadoria Rural', label: 'Aposentadoria Rural' },
  {
    value: 'Aposentadoria por Tempo de Contribuição',
    label: 'Aposentadoria por Tempo de Contribuição'
  },
  { value: 'Auxílio Acidente', label: 'Auxílio Acidente' },
  { value: 'Auxílio Doença', label: 'Auxílio Doença' },
  { value: 'Auxílio Reclusão', label: 'Auxílio Reclusão' },
  { value: 'BPC', label: 'BPC' },
  { value: 'Cannabis Medicinal', label: 'Cannabis Medicinal' },
  { value: 'Constitucionais', label: 'Constitucionais' },
  { value: 'Direito do Idoso', label: 'Direito do Idoso' },
  { value: 'LGBT+', label: 'LGBT+' },
  { value: 'LOAS', label: 'LOAS' },
  { value: 'Pensão por Morte', label: 'Pensão por Morte' },
  { value: 'Privacidade', label: 'Privacidade' },
  { value: 'Posse e Porte de Armas', label: 'Posse e Porte de Armas' },
  { value: 'Povos indígenas', label: 'Povos indígenas' },
  { value: 'Registro Civil', label: 'Registro Civil' },
  { value: 'Salário Família', label: 'Salário Família' },
  { value: 'Salário Maternidade', label: 'Salário Maternidade' },
  { value: 'SUS', label: 'SUS' }
];

const consumidores = [
  {
    value: 'Atraso, Defeito e Troca de Produto',
    label: 'Atraso, Defeito e Troca de Produto'
  },
  {
    value: 'Cobrança Indevida ou Abusiva',
    label: 'Cobrança Indevida ou Abusiva'
  },
  {
    value: 'Constrangimento em Cobrança',
    label: 'Constrangimento em Cobrança'
  },
  { value: 'Dano Material', label: 'Dano Material' },
  { value: 'Dano Moral', label: 'Dano Moral' },
  { value: 'Lucros Cessantes', label: 'Lucros Cessantes' },
  { value: 'Perdas e Danos', label: 'Perdas e Danos' },
  {
    value: 'Prestações de Serviços Incompletos ou não Realizados',
    label: 'Prestações de Serviços Incompletos ou não Realizados'
  },
  { value: 'Propaganda Abusiva', label: 'Propaganda Abusiva' },
  { value: 'Propagando Enganosa', label: 'Propagando Enganosa' },
  { value: 'Protesto Indevido', label: 'Protesto Indevido' },
  { value: 'Problemas com Bancos', label: 'Problemas com Bancos' },
  {
    value: 'Problemas com Companhias Aéreas',
    label: 'Problemas com Companhias Aéreas'
  },
  {
    value: 'Problemas com Companhias Marítimas',
    label: 'Problemas com Companhias Marítimas'
  },
  {
    value: 'Problemas com Companhias Rodoviárias',
    label: 'Problemas com Companhias Rodoviárias'
  },
  {
    value: 'Problemas com Compras pela Internet',
    label: 'Problemas com Compras pela Internet'
  },
  {
    value: 'Problemas com Concessionárias de Água e Luz',
    label: 'Problemas com Concessionárias de Água e Luz'
  },
  {
    value: 'Problemas com Concessionárias de Veículos',
    label: 'Problemas com Concessionárias de Veículos'
  },
  { value: 'Problemas com Hospitais', label: 'Problemas com Hospitais' },
  {
    value: 'Problemas com Operadoras de Telefonia',
    label: 'Problemas com Operadoras de Telefonia'
  },
  {
    value: 'Problemas com Plano de Saúde',
    label: 'Problemas com Plano de Saúde'
  },
  { value: 'Problemas com Seguradoras', label: 'Problemas com Seguradoras' },
  { value: 'Reajustes e Juros Abusivos', label: 'Reajustes e Juros Abusivos' },
  { value: 'Reparação de Ato Ilícito', label: 'Reparação de Ato Ilícito' }
];

const criminais = [
  { value: 'Abuso de Autoridade', label: 'Abuso de Autoridade' },
  { value: 'Abuso de Menor Adolescente', label: 'Abuso de Menor Adolescente' },
  {
    value: 'Abuso e Negligência em Lar De Idosos',
    label: 'Abuso e Negligência em Lar De Idosos'
  },
  { value: 'Assédio', label: 'Assédio' },
  { value: 'Calúnia e Difamação', label: 'Calúnia e Difamação' },
  { value: 'Crimes Contra Honra', label: 'Crimes Contra Honra' },
  {
    value: 'Crimes Contra o Meio Ambiente',
    label: 'Crimes Contra o Meio Ambiente'
  },
  { value: 'Crimes do Colarinho Branco', label: 'Crimes do Colarinho Branco' },
  { value: 'Crimes de Drogas', label: 'Crimes de Drogas' },
  { value: 'Crimes Econômicos', label: 'Crimes Econômicos' },
  { value: 'Crimes Federais', label: 'Crimes Federais' },
  { value: 'Crimes Militares', label: 'Crimes Militares' },
  { value: 'Crimes Violentos', label: 'Crimes Violentos' },
  { value: 'Crimes Sexuais', label: 'Crimes Sexuais' },
  { value: 'Execução Penal', label: 'Execução Penal' },
  { value: 'Discriminação', label: 'Discriminação' },
  {
    value: 'Fraude de Cartão de Crédito',
    label: 'Fraude de Cartão de Crédito'
  },
  { value: 'Fraude de Computador', label: 'Fraude de Computador' },
  { value: 'Fraude e Evasão Fiscal', label: 'Fraude e Evasão Fiscal' },
  {
    value: 'Fraude de Títulos e Investimentos',
    label: 'Fraude de Títulos e Investimentos'
  },
  { value: 'Homicídio Culposo e Doloso', label: 'Homicídio Culposo e Doloso' },
  { value: 'Lei de Imprensa', label: 'Lei de Imprensa' },
  { value: 'Lei de Lavagem de Dinheiro', label: 'Lei de Lavagem de Dinheiro' },
  { value: 'Lesão por Animais', label: 'Lesão por Animais' },
  { value: 'Maria da Penha', label: 'Maria da Penha' },
  { value: 'Menores Infratores', label: 'Menores Infratores' },
  { value: 'Negligência Médica', label: 'Negligência Médica' },
  { value: 'Preconceito', label: 'Preconceito' },
  { value: 'Racismo', label: 'Racismo' },
  { value: 'Roubo de Identidade', label: 'Roubo de Identidade' }
];

const trabalhistas = [
  { value: 'Acidente de Trabalho', label: 'Acidente de Trabalho' },
  {
    value: 'Acúmulo ou Desvio de Função',
    label: 'Acúmulo ou Desvio de Função'
  },
  { value: 'Adicionais de Risco', label: 'Adicionais de Risco' },
  { value: 'Benefícios do Empregado', label: 'Benefícios do Empregado' },
  { value: 'Compensações', label: 'Compensações' },
  { value: 'Demissão Voluntária', label: 'Demissão Voluntária' },
  { value: 'Empregada Gestante', label: 'Empregada Gestante' },
  { value: 'Férias', label: 'Férias' },
  { value: 'FGTS', label: 'FGTS' },
  { value: 'INSS', label: 'INSS' },
  { value: 'Reclamação Trabalhista', label: 'Reclamação Trabalhista' },
  {
    value: 'Reconhecimento de Vínculo Trabalhista',
    label: 'Reconhecimento de Vínculo Trabalhista'
  },
  { value: 'Reintegração', label: 'Reintegração' },
  { value: 'Rescisão Indevida', label: 'Rescisão Indevida' }
];

const transito = [
  { value: 'Acidente de Caminhão', label: 'Acidente de Caminhão' },
  { value: 'Acidente de Carro', label: 'Acidente de Carro' },
  { value: 'Acidente de Moto', label: 'Acidente de Moto' },
  { value: 'Acidentes de Trânsito', label: 'Acidentes de Trânsito' },
  {
    value: 'Compra, Venda e Transferência de Veículo',
    label: 'Compra, Venda e Transferência de Veículo'
  },
  { value: 'DPVAT', label: 'DPVAT' },
  { value: 'Lei Seca', label: 'Lei Seca' },
  {
    value: 'Multa, Apreensão da CNH e Problemas Administrativos',
    label: 'Multa, Apreensão da CNH e Problemas Administrativos'
  }
];

const planejamento = [
  { value: 'Patrimonial', label: 'Patrimonial' },
  { value: 'Previdenciário', label: 'Previdenciário' },
  { value: 'Procuração', label: 'Procuração' },
  { value: 'Sucessões', label: 'Sucessões' },
  { value: 'Testamentos', label: 'Testamentos' },
  { value: 'Trusts', label: 'Trusts' }
];

const family = [
  { value: 'Adoção', label: 'Adoção' },
  { value: 'Abuso Infantil', label: 'Abuso Infantil' },
  { value: 'Casamento e Pré-nupciais', label: 'Casamento e Pré-nupciais' },
  { value: 'Curatela', label: 'Curatela' },
  { value: 'Custódia da Criança', label: 'Custódia da Criança' },
  { value: 'Divórcio e Separação', label: 'Divórcio e Separação' },
  { value: 'Emancipação', label: 'Emancipação' },
  { value: 'Guarda Compartilhada', label: 'Guarda Compartilhada' },
  { value: 'Interdição', label: 'Interdição' },
  { value: 'Pensão Alimentícia', label: 'Pensão Alimentícia' },
  {
    value: 'Reconhecimento de Paternidade',
    label: 'Reconhecimento de Paternidade'
  },
  { value: 'Regulação de Visitas', label: 'Regulação de Visitas' },
  { value: 'União Estável', label: 'União Estável' }
];

const governo = [
  { value: 'Campanhas Eleitorais', label: 'Campanhas Eleitorais' },
  {
    value: 'Candidatos, Partidos Políticos e Coligações',
    label: 'Candidatos, Partidos Políticos e Coligações'
  },
  { value: 'Cassação de Mandato', label: 'Cassação de Mandato' },
  { value: 'Concurso Público', label: 'Concurso Público' },
  { value: 'Direitos Políticos', label: 'Direitos Políticos' },
  { value: 'Inelegibilidade', label: 'Inelegibilidade' },
  { value: 'Isenção de Impostos', label: 'Isenção de Impostos' },
  { value: 'Legislação Administrativa', label: 'Legislação Administrativa' },
  { value: 'Legislação Militar', label: 'Legislação Militar' },
  {
    value: 'Legislação Municipal, Estadual e Federal',
    label: 'Legislação Municipal, Estadual e Federal'
  },
  { value: 'Legislação Política', label: 'Legislação Política' },
  { value: 'Licitações', label: 'Licitações' },
  { value: 'Parceria Público Privada', label: 'Parceria Público Privada' },
  { value: 'Privatizações', label: 'Privatizações' },
  { value: 'Projetos Governamentais', label: 'Projetos Governamentais' },
  { value: 'Relações Governamentais', label: 'Relações Governamentais' },
  { value: 'Servidor Público', label: 'Servidor Público' }
];

const immigration = [
  { value: 'Asilo', label: 'Asilo' },
  { value: 'Deportação', label: 'Deportação' },
  { value: 'Direito Internacional', label: 'Direito Internacional' },
  { value: 'Dupla Nacionalidade', label: 'Dupla Nacionalidade' },
  { value: 'Visto Internacional', label: 'Visto Internacional' }
];

const realState = [
  { value: 'Alvarás e Adequações', label: 'Alvarás e Adequações' },
  {
    value: 'Atraso na Entrega de Imóvel',
    label: 'Atraso na Entrega de Imóvel'
  },
  {
    value: 'Compra e Venda de Imóvel e Terreno',
    label: 'Compra e Venda de Imóvel e Terreno'
  },
  {
    value: 'Convenção e Regimento Interno',
    label: 'Convenção e Regimento Interno'
  },
  { value: 'Condomínio', label: 'Condomínio' },
  {
    value: 'Construção e Desenvolvimento',
    label: 'Construção e Desenvolvimento'
  },
  { value: 'Despejo', label: 'Despejo' },
  { value: 'Destrato de Compra e Venda', label: 'Destrato de Compra e Venda' },
  { value: 'Destrato de Locação', label: 'Destrato de Locação' },
  { value: 'Execução de Hipoteca', label: 'Execução de Hipoteca' },
  { value: 'Imóveis Comerciais', label: 'Imóveis Comerciais' },
  { value: 'Imóveis Residenciais', label: 'Imóveis Residenciais' },
  { value: 'Inquilinato', label: 'Inquilinato' },
  { value: 'Locação de Imóvel', label: 'Locação de Imóvel' },
  { value: 'Loteamento', label: 'Loteamento' },
  { value: 'Reintegração de Posse', label: 'Reintegração de Posse' },
  { value: 'Usucapião', label: 'Usucapião' },
  { value: 'Tombamento', label: 'Tombamento' }
];

const propriedadeIntelectual = [
  {
    value: 'Contratos de Tecnologia e Franquia',
    label: 'Contratos de Tecnologia e Franquia'
  },
  { value: 'Desenhos Industriais', label: 'Desenhos Industriais' },
  { value: 'Direito Autoral', label: 'Direito Autoral' },
  { value: 'Indicações Geográficas', label: 'Indicações Geográficas' },
  { value: 'Registros Internacionais', label: 'Registros Internacionais' },
  {
    value: 'Registro de Marca/Logomarca',
    label: 'Registro de Marca/Logomarca'
  },
  { value: 'Registro de Patente', label: 'Registro de Patente' },
  { value: 'Registro de Software', label: 'Registro de Software' },
  {
    value: 'Violação de Direitos Autorais',
    label: 'Violação de Direitos Autorais'
  },
  {
    value: 'Violação de Marca Registrada',
    label: 'Violação de Marca Registrada'
  },
  {
    value: 'Violação de Patente Registrada',
    label: 'Violação de Patente Registrada'
  },
  {
    value: 'Violação de Software Registrada',
    label: 'Violação de Software Registrada'
  },
  {
    value: 'Topográficas de Circuitos Integrados',
    label: 'Topográficas de Circuitos Integrados'
  }
];

const Services = [
  { value: 'Acompanhamentos', label: 'Acompanhamentos' },
  { value: 'Alvarás', label: 'Alvarás' },
  { value: 'Andamentos', label: 'Andamentos' },
  { value: 'Análises', label: 'Análises' },
  { value: 'Audiências', label: 'Audiências' },
  { value: 'Buscas e Apreensões', label: 'Buscas e Apreensões' },
  { value: 'Cargas', label: 'Cargas' },
  { value: 'Certidões', label: 'Certidões' },
  { value: 'Conciliação', label: 'Conciliação' },
  { value: 'Consultas', label: 'Consultas' },
  { value: 'Cópias', label: 'Cópias' },
  { value: 'Despachos', label: 'Despachos' },
  { value: 'Diligências', label: 'Diligências' },
  { value: 'Distribuições', label: 'Distribuições' },
  { value: 'Elaboração de Teses', label: 'Elaboração de Teses' },
  { value: 'Exame de Processos', label: 'Exame de Processos' },
  { value: 'Guias', label: 'Guias' },
  { value: 'Imobiliário', label: 'Imobiliário' },
  { value: 'Liquidação de Sentença', label: 'Liquidação de Sentença' },
  { value: 'Mandados', label: 'Mandados' },
  { value: 'Mediação', label: 'Mediação' },
  { value: 'Ofícios', label: 'Ofícios' },
  { value: 'Outros', label: 'Outros' },
  { value: 'Pareceres', label: 'Pareceres' },
  { value: 'Pesquisas', label: 'Pesquisas' },
  { value: 'Peças', label: 'Peças' },
  { value: 'Prazos', label: 'Prazos' },
  { value: 'Preposição', label: 'Preposição' },
  { value: 'Protocolos', label: 'Protocolos' },
  { value: 'Recursos', label: 'Recursos' },

  { value: 'Sustentações Orais', label: 'Sustentações Orais' }
];

const acoesJudiciaisAndODR = [
  { value: 'Ação Civil Pública', label: 'Ação Civil Pública' },
  { value: 'Ação Coletiva', label: 'Ação Coletiva' },
  { value: 'Ação de Improbidade', label: 'Ação de Improbidade' },
  { value: 'Ação Indenizatória', label: 'Ação Indenizatória' },
  { value: 'Audiência de Custódia', label: 'Audiência de Custódia' },
  { value: 'Arbitragem', label: 'Arbitragem' },
  { value: 'Conciliação', label: 'Conciliação' },
  { value: 'Embargos', label: 'Embargos' },
  { value: 'Habeas Corpus', label: 'Habeas Corpus' },
  { value: 'Interdição', label: 'Interdição' },
  { value: 'Liberdade Provisória', label: 'Liberdade Provisória' },
  { value: 'Litígio', label: 'Litígio' },
  { value: 'Liminares', label: 'Liminares' },
  { value: 'Mandado de Segurança', label: 'Mandado de Segurança' },
  { value: 'Mediação', label: 'Mediação' },
  { value: 'Peticionamentos', label: 'Peticionamentos' },
  { value: 'Pro Bono', label: 'Pro Bono' },
  { value: 'Recursos', label: 'Recursos' }
];
export const LegalCorrespondenceOptions = [
  { label: "Serviços", options: Services },
];

const allOccupationAreasOptions = [
  { value: 'Mercados', options: markets },
  { value: 'Propriedade Intelectual', options: propriedadeIntelectual },
  { value: 'Ações Jurídicas e ORD', options: acoesJudiciaisAndODR },
  { value: 'Imobiliário', options: realState },
  { value: 'Imigração', options: immigration },
  { value: 'Familia', options: family },
  { value: 'Trabalhista', options: trabalhistas },
  { value: 'Governo', options: governo },
  { value: 'Planejamento', options: planejamento },
  { value: 'Trânsito', options: transito },
  { value: 'Criminal', options: criminais },
  { value: 'Consumidor', options: consumidores },
  { value: 'Finanças', options: finances },
  { value: 'Gestão', options: management },
  { value: 'Serviços', options: Services },
  { value: 'Civís', options: civis },
  { value: 'Comunicação e Marketing', options: communicationAndMarketing },
  { value: 'Dívida e Falência', options: bankruptcyAndDebt }
];

export const allOptionsForSelectInput = [
  { label: 'Especialidades', options: allSpecialtiesOptions },
  { value: 'Mercados', options: markets },
  { value: 'Propriedade Intelectual', options: propriedadeIntelectual },
  { value: 'Ações Jurídicas e ORD', options: acoesJudiciaisAndODR },
  { value: 'Imobiliário', options: realState },
  { value: 'Imigração', options: immigration },
  { value: 'Familia', options: family },
  { value: 'Trabalhista', options: trabalhistas },
  { value: 'Governo', options: governo },
  { value: 'Planejamento', options: planejamento },
  { value: 'Trânsito', options: transito },
  { value: 'Criminal', options: criminais },
  { value: 'Serviços', options: Services },
  { value: 'Consumidor', options: consumidores },
  { value: 'Finanças', options: finances },
  { value: 'Gestão', options: management },
  { value: 'Civís', options: civis },
  { value: 'Comunicação e Marketing', options: communicationAndMarketing },
  { value: 'Dívida e Falência', options: bankruptcyAndDebt }
];

export default allOccupationAreasOptions;
