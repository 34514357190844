import { useField } from "@unform/core";
import React, {
  InputHTMLAttributes,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { FiAlertTriangle } from "react-icons/fi";
import { IconBaseProps } from "react-icons/lib";
import * as Styled from "./styled.Input";

interface inputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  icon?: React.ComponentType<IconBaseProps>;
  row?: boolean;
  label?: string;
}

const Input: React.FC<inputProps> = ({
  icon: Icon,
  label,
  row,
  name,
  ...rest
}) => {
  const InputRef = useRef<HTMLInputElement>(null);

  const [isFocused, SetIsFocused] = useState(false);
  const [isFilled, SetIsFilled] = useState(false);

  const { fieldName, defaultValue, error, registerField } = useField(name);

  const handleInputBlur = useCallback(() => {
    SetIsFocused(false);
    // transforma o fato de ter conteúdo ou não no value do input em boolean
    SetIsFilled(!!InputRef.current?.value);
  }, []);

  const handleInputFocus = useCallback(() => {
    SetIsFocused(true);
  }, []);

  useEffect(() => {
    registerField({ name: fieldName, ref: InputRef.current, path: "value" });
  }, [registerField, fieldName]);

  return (
    <Styled.Container
      isErrored={!!error}
      isFilled={isFilled}
      isFocused={isFocused}
      isColumned={!!row}
    >
      {label && (
        <Styled.Label
          isErrored={!!error}
          isFilled={isFilled}
          isFocused={isFocused}
          isColumned={!!row}
        >
          {label}
        </Styled.Label>
      )}
      {Icon && <Icon size={20} />}
      <input
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
        defaultValue={defaultValue}
        ref={InputRef}
        {...rest}
      />

      {error && (
        <Styled.Errors title={error}>
          <FiAlertTriangle color="red" size={20} />
        </Styled.Errors>
      )}
    </Styled.Container>
  );
};

export default Input;
