import React, { useEffect, useState } from "react";
import { Row, Col, Space, Radio } from "antd";

import { ILawyerInformation } from "../../../@types/ILawyerInformation";
import { TagProps } from "antd/lib/tag";
import { AllTagsArray, DefineUserTags, TagUserProp } from "./functions";
import { compact } from "lodash";
import { IUser } from "../../../@types/IUser";
interface FiltersTableProps {
  users: IUser[];
  SetUsers(set: React.SetStateAction<IUser[]>): void;
}
const FiltersTable: React.FC<FiltersTableProps> = ({ users, SetUsers }) => {
  const [FilterTag, SetFilterState] = useState<TagUserProp>("" as TagUserProp);

  const onChangeRadioActionsPartneshipApplication = (e: any) => {
    const newTag = e.target.value;
    SetFilterState(newTag);
  };

  function FilterByTags(layers: IUser[]) {
    if (FilterTag === "Advogado") {
      const filtered = users.filter((usr) => usr.type === "lawyer");
      SetUsers(filtered);
      return;
    }
    if (FilterTag === "Usuário") {
      const filtered = users.filter((usr) => usr.type === "user");
      SetUsers(filtered);
      return;
    }
    if (FilterTag !== "Todos") {
      const lawy = users.map((lawyer) => {
        const tags = DefineUserTags(lawyer);
        console.log("TAG", tags);
        const state = tags.some((item) => item === FilterTag);
        if (state) {
          return lawyer;
        }
        return;
      });
      const result = compact(lawy);
      return SetUsers(result);
    }
    SetUsers(users);
  }

  useEffect(() => {
    if (users) {
      FilterByTags(users);
    }
  }, [FilterTag]);

  return (
    <>
      <Space direction="vertical">
        <h3>Filtrar por tags</h3>
        <Radio.Group
          size="large"
          onChange={onChangeRadioActionsPartneshipApplication}
          value={FilterTag}
        >
          {AllTagsArray.map((tag) => {
            return (
              <>
                <Radio.Button value={tag}>{tag}</Radio.Button>
              </>
            );
          })}
        </Radio.Group>
      </Space>
    </>
  );
};

export default FiltersTable;
