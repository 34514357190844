import { Button, Space, Table, Tag } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTheme } from "styled-components";
import { ILawyerInformation } from "../../@types/ILawyerInformation";
import { IUser } from "../../@types/IUser";
import AllLawyerInformationModal from "./Subcomponents/AllLawyerInformationModal";
import FiltersTable from "./Subcomponents/filters";
import {
  defineUserTagColor,
  DefineUserTags,
  TagUserProp,
} from "./Subcomponents/functions";
moment.locale("pt-br");
interface TableProps {
  userData: IUser[];
  LoadingState: boolean;
}

const UserListTable: React.FC<TableProps> = ({
  userData: userDataFrom,
  LoadingState,
}) => {
  const [IsModalVisible, SetIsModalVisible] = useState(false);
  const [ModalLawyerInfo, SetModalLawyerInfo] = useState<IUser>({} as IUser);
  const { colors } = useTheme();

  const [lawyerData, SetLawyerSelection] = useState<IUser[]>([] as IUser[]);

  useEffect(() => {
    SetLawyerSelection(userDataFrom);
  }, [userDataFrom]);

  function handleOpenModal(userData: IUser) {
    SetModalLawyerInfo(userData);
    SetIsModalVisible(true);
  }

  const columns = [
    {
      title: "Nome",
      dataIndex: "name",
      key: "name",
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      render: (text: string) => <a href="#">{text}</a>,
    },

    {
      title: "email",
      dataIndex: "email",
      key: "email",
    },

    {
      title: "Criação",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Tags",
      key: "tags",
      dataIndex: "tags",
      render: (tags: TagUserProp[]) => (
        <>
          {tags.map((tag: TagUserProp) => {
            let color = defineUserTagColor(tag);

            return (
              <Tag color={color} key={tag}>
                {tag}
              </Tag>
            );
          })}
        </>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (text: string, record: any) => (
        // <Space size="middle">
        //   <Button
        //     type="primary"
        //     onClick={() => handleOpenModal(record.lawyerData)}
        //   >
        //     Ver Mais
        //   </Button>
        // </Space>
        <></>
      ),
    },
  ];

  const data = lawyerData.map((user) => {
    const tags = DefineUserTags(user);
    return {
      key: user.first_name,
      name: `${user.first_name} ${user.last_name}`,

      email: `${user.email}`,
      tags: tags,

      date: moment(user.created_at).calendar(),
    };
  });

  return (
    <>
      <Space
        direction="vertical"
        style={{
          width: "100%",
        }}
      >
        <FiltersTable SetUsers={SetLawyerSelection} users={userDataFrom} />
        <Table columns={columns} dataSource={data} />
      </Space>
      ;
    </>
  );
};

export default UserListTable;
