import { Col, Empty, Row, Space } from "antd";
import React, { useEffect, useState } from "react";
import { Container } from "./styles";
import * as S from "./styles";

import { Helmet } from "react-helmet";

import DemandSection, { DemandObj } from "./section/demandSection";
import moment from "moment";
import { AiOutlinePlus } from "react-icons/ai";

import { useHistory } from "react-router-dom";

import { GrFilter } from "react-icons/gr";
import DemandFilters from "./Filters/filters.index";

import { compact, has } from "lodash";
import { useResponsiveness } from "../../hooks/Responsiveness";
import { useAuth } from "../../hooks/auth";
import { useToast } from "../../hooks/Toast";
import {
  DeleteDemand,
  ListDemands,
  ListDemandsProps,
} from "../../utils/RoutesFunctions/funcion.demands";
import { GetUserAvatar } from "../../utils/RoutesFunctions/functions.avatar";
import Loading from "../../Components/Loading/Loading";
import { DefaultButtonStyled } from "../SignIn/styleSignIn";
import ScrollToTop from "../../utils/ScroolToTop";
import PageLayout from "../../Components/Layout/Layout";
import Backend from "../../config/backendAdress";
import { seo } from "../../config/SEO";

const DemandsSelectionPage: React.FC = () => {
  const { ScreenMD } = useResponsiveness();
  const history = useHistory();
  const { user } = useAuth();
  const [LocationFilter, SetLocationFilter] = useState("");
  const [SpecialtieFilter, SetSpecialtieFilter] = useState("");
  const [InitialFetchState, SetInitialFetchState] = useState(true);
  const [LoadingState, SetLoadingState] = useState(true);
  const [LoadMoreState, SetLoadMoreState] = useState(false);

  const [DemandsListPage, SetDemandsListPage] = useState(0);

  const [DemandsToList, SetDemandsToList] = useState<DemandObj[]>(
    [] as DemandObj[]
  );

  const [FinalOfTheListState, SetFinalOfTheListState] = useState(false);

  const { addToast } = useToast();

  const PageSEO = {
    title: `Demandas | ${seo.websiteName}`,
  };

  async function DeleteUserDemand(demandId: string) {
    try {
      SetLoadingState(true);

      await DeleteDemand(demandId);

      GetDemands(0);
      SetLocationFilter("");
      SetSpecialtieFilter("");
      addToast({
        title: "Demanda deletada com sucesso",
        type: "success",
      });
      SetLoadingState(false);
    } catch (error) {
      SetLoadingState(false);
      addToast({
        title: "Erro ao excluir a demanda",
        type: "error",
      });
    }
  }

  async function GetDemands(
    page: number,
    filters?: ListDemandsProps["filters"]
  ) {
    try {
      page === 0 ? SetLoadingState(true) : SetLoadMoreState(true);

      const response = await ListDemands({
        page,
        filters,
      });

      const demandsResp = response.data.sort((a, b) =>
        moment(moment(b.created_at).unix()).diff(moment(a.created_at).unix())
      );

      if (demandsResp.length < 15) {
        SetFinalOfTheListState(true);
      } else {
        SetFinalOfTheListState(false);
      }

      SetInitialFetchState(false);

      if (demandsResp.length === 0) {
        page === 0 ? SetLoadingState(false) : SetLoadMoreState(false);
        SetDemandsListPage(0);
        return SetDemandsToList([]);
      }

      if (demandsResp.length !== 0) {
        console.log("ASDADAD", demandsResp, demandsResp.length);

        const demands = demandsResp.map(async (demand) => {
          console.log("demand", demand);
          const userAvatar = await GetUserAvatar(demand.created_by);
          console.log(userAvatar.data.avatar);
          let url: string;
          if (userAvatar.data.avatar === "default-avatar.png") {
            url = `https://ui-avatars.com/api/?name=${demand.name.first_name}+${demand.name.last_name}`;
          } else {
            url = `${Backend.files}/${userAvatar.data.avatar}`;
          }

          const data: DemandObj = {
            id: `${demand.id}`,
            avatarUrl: url,
            specialty: demand.specialty[0],
            date: demand.created_at,
            description: demand.description,
            details: demand.additionalDetails,
            location: demand.location,
            name: `${demand.name.first_name} ${demand.name.last_name}`,
            phone: demand.phone,
            title: demand.title,
            createdBy: demand.created_by,
            lawyerContactedFrom: demand.contacts_from,
            onDeleteDemand: async (demandId) =>
              await DeleteUserDemand(demandId),
          };
          return data;
        });
        Promise.all(demands).then((res) => {
          console.log("RES", res, "compact", compact(res));
          page === 0 ? SetLoadingState(false) : SetLoadMoreState(false);
          if (page > 0) {
            return SetDemandsToList((n) => [...n, ...compact(res)]);
          }
          SetDemandsToList(compact(res));
        });
      }
    } catch (error) {
      page === 0 ? SetLoadingState(false) : SetLoadMoreState(false);
      addToast({
        title: "Erro ao carregar as demandas, tente novamente",
        type: "error",
      });
    }
  }

  function LoadMore() {
    const page = DemandsListPage;
    const nextPage = page + 1;
    SetDemandsListPage(nextPage);
    let filters = undefined;
    if (SpecialtieFilter || LocationFilter) {
      const filtersRes: ListDemandsProps["filters"] = {
        locations: LocationFilter ? [LocationFilter] : [],
        specialties: SpecialtieFilter ? [SpecialtieFilter] : [],
      };
      filters = filtersRes;
    }
    GetDemands(nextPage, filters);
  }

  useEffect(() => {
    if (!InitialFetchState) {
      SetDemandsListPage(0);
      if (SpecialtieFilter || LocationFilter) {
        const filtersRes: ListDemandsProps["filters"] = {
          locations: LocationFilter ? [LocationFilter] : [],
          specialties: SpecialtieFilter ? [SpecialtieFilter] : [],
        };
        console.log(filtersRes);
        GetDemands(0, filtersRes);
      }
      if (!SpecialtieFilter && !LocationFilter) {
        GetDemands(0);
      }
    }
  }, [SpecialtieFilter, LocationFilter]);

  useEffect(() => {
    GetDemands(DemandsListPage);
  }, []);

  const Head: React.FC = () => (
    <>
      <Helmet>
        <title>{PageSEO.title}</title>

        <meta name="robots" content="Noindex" />
      </Helmet>
    </>
  );

  const AddNewDemandHeaderRender = () => (
    <Row>
      <Col
        span={24}
        style={{
          margin: !ScreenMD ? "0 4rem" : "0",
          display: "flex",
          justifyContent: !ScreenMD ? "flex-start" : "center",
        }}
      >
        <DemandFilters
          filters={{
            SpecialtieFilterValue: SpecialtieFilter,
            SetSpecialtieFilterValue: SetSpecialtieFilter,
            SetLocationFilterValue: SetLocationFilter,
            locationFilterValue: LocationFilter,
          }}
        />
        {/* <TransparentButton
          onClick={() => history.push('/escritorio/assistente')}
        >
          <GrFilter size={15} style={{ marginRight: 5 }} /> Filtrar
        </TransparentButton> */}
      </Col>
    </Row>
  );

  const TitleRender = () => (
    <Row gutter={[32, 32]} justify="center">
      <S.MainTitle>
        <Space direction="vertical" size="large" align="center">
          <Col xs={24} sm={24} md={15} lg={24} xl={24}>
            <h1>Demandas Disponíveis</h1>
          </Col>
        </Space>
      </S.MainTitle>
    </Row>
  );

  const fakeDemands: DemandObj[] = [
    {
      id: "1",
      title: "this is a demand title",
      description: "abcdefcg asdas sdsdfsfsdf",
      avatarUrl:
        "https://c6oxm85c.cloudimg.io/cdno/n/q85/https://az617363.vo.msecnd.net/imgmodels/models/MD10003095/1027798-800w258c1682a3705f8109330d7257579629_thumb.jpg",
      name: "Davi Tavares Mirnada",
      phone: "33 3423 2922",
      date: moment(),
      lawyerContactedFrom: [],
      specialty: "x",
      createdBy: "",
      location: {
        cep: "39750000",
        city: "Sabinópolis",
        state: "MG",
      },
      details: [
        {
          title: "Processo Ajuizado.",
          description: "",
          isFact: true,
        },
        {
          title: "Numero Do Processo",
          description: "ac 1231412",
          isFact: false,
        },
      ],
    },
    {
      id: "2",
      title: "this is a demand title",
      description: "abcdefcg asdas sdsdfsfsdf",
      avatarUrl:
        "https://c6oxm85c.cloudimg.io/cdno/n/q85/https://az617363.vo.msecnd.net/imgmodels/models/MD10003095/1027798-800w258c1682a3705f8109330d7257579629_thumb.jpg",
      name: "Davi Tavares Mirnada",
      phone: "33 3423 2922",
      specialty: "x",
      createdBy: "",
      date: moment(),
      location: {
        cep: "39750000",
        city: "Sabinópolis",

        state: "MG",
      },
      details: [
        {
          title: "Processo Ajuizado.",
          description: "",
          isFact: true,
        },
        {
          title: "Numero Do Processo",
          description: "ac 1231412",
          isFact: false,
        },
      ],
    },
  ];

  const DemandsRender = () => (
    <>
      {!LoadingState && DemandsToList.length === 0 ? (
        <div
          style={{
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Empty description={<p>Nenhum resultado foi encontrado</p>} />
        </div>
      ) : (
        <>
          {DemandsToList.map((Demand) => (
            <Row key={Demand.id}>
              <Col span={24}>
                <DemandSection data={Demand} key={Demand.id} />
              </Col>
            </Row>
          ))}
        </>
      )}
    </>
  );

  const LoadMoreButtonRender = () => (
    <div
      style={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        margin: "0.7rem 0",
      }}
    >
      <Loading loading={LoadMoreState}>
        {!FinalOfTheListState && !LoadingState && DemandsToList.length !== 0 && (
          <DefaultButtonStyled
            style={{
              visibility: LoadMoreState ? "hidden" : "visible",
            }}
            onClick={() => LoadMore()}
          >
            Carregar Mais
          </DefaultButtonStyled>
        )}
        {FinalOfTheListState && DemandsToList.length > 15 && !LoadingState && (
          <>Todos os resultados foram listados</>
        )}
      </Loading>
    </div>
  );
  return (
    <>
      <ScrollToTop />
      <Head />
      <div>
        <PageLayout>
          <Container>
            {/* <p
            style={{
              top: 10,
              right: 10,
              position: 'absolute',
              cursor: 'pointer'
            }}
            onClick={() => history.push('/escritorio/demandas/user')}
          >
            Ver Minhas Demandas
          </p> */}
            {TitleRender()}

            {AddNewDemandHeaderRender()}
            <Loading loading={LoadingState}>
              {DemandsRender()}
              {LoadMoreButtonRender()}
            </Loading>
          </Container>
        </PageLayout>
      </div>
    </>
  );
};

export default DemandsSelectionPage;
