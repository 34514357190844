import styled, { css } from 'styled-components';
import Tooltip from '../Tooltip/Tooltip.Index';

interface ContainerProps {
  isErrored: boolean;
  haveIcon: boolean;
}
export const StyledSelect = styled.div<ContainerProps>`
  position: relative;
  margin-bottom: 1.3rem;
  margin-top: 1.3rem;

  p {
    margin-top: 0.2rem;
    font-size: 0.85rem;
    width: auto;
    color: ${props => props.theme.colors.lightText};
  }

  .react-select-container {
    max-width: 37rem;
    .react-select__control {
      background: ${props => props.theme.colors.whiteOrDark};
      border-radius: 0.8rem;

      color: ${props => props.theme.colors.text};
      border: 2px solid ${props => props.theme.colors.lightGrey};

      ${props =>
        props.isErrored &&
        css`
          border: 2px solid #c53030;
        `}

      padding: 6px;
      ${props =>
        props.haveIcon &&
        css`
          padding-left: 1.6rem;
        `}
    }
  }
  .react-select__menu {
    z-index: 999;
  }
`;

export const Label = styled.span`
  z-index: 1;
  transition: all 0.2s;

  position: absolute;
  left: 1rem;
  top: -0.6rem;

  font-size: 0.9rem;

  color: ${props => props.theme.colors.lightText};
  background: ${props => props.theme.colors.whiteOrDark};

  border-left: 0.5rem solid ${props => props.theme.colors.whiteOrDark};
  border-right: 0.5rem solid ${props => props.theme.colors.whiteOrDark};
`;

export const Icon = styled.div`
  position: absolute;
  top: 1rem;
  left: 0.8rem;
  z-index: 10000;
  color: ${props => props.theme.colors.lightGrey};
`;

export const Errors = styled(Tooltip)`
  position: absolute;
  top: 0.9rem;
  right: 0.7rem;

  background-color: ${props => props.theme.colors.whiteOrDark};
  padding: 0.1rem;

  height: 20px;
  margin-left: 16px;
  svg {
    margin: 0;
  }

  span {
    background: #c53030;
    color: #fff;

    &::before {
      border-color: #c53030 transparent;
    }
  }
`;
