import { message } from "antd";
import { has } from "lodash";
import { ILawyerInformation } from "../../../@types/ILawyerInformation";
import { IUser } from "../../../@types/IUser";
import api from "../../../services/api";
import {
  ChangeLawyerVerifiedStatus,
  ChangeLawyerVerifiedStatusProps,
  EditLawyerInformation,
} from "../../../utils/RoutesFunctions/functions.Lawyers";
import { GetUserData } from "../../../utils/RoutesFunctions/functions.Users";
type AdditionalInfo = ILawyerInformation["additional_information"];
interface ChangeLawyerPartneshipApplicationProps {
  lawyerData: Exclude<ILawyerInformation, undefined>;
  newPartneshipTag: "true" | "false" | "approved" | "rejected" | "verify";
  lawyerId: string;
}

export async function ChangeLawyerPartneshipApplication({
  lawyerData,
  newPartneshipTag,
  lawyerId,
}: ChangeLawyerPartneshipApplicationProps) {
  try {
    console.log({ lawyerId, lawyerData, newPartneshipTag });
    if (lawyerData.lawyer_id) {
      const userData = await GetUserData(lawyerData.lawyer_id);

      if (has(lawyerData, "additional_information")) {
        const additional_information: ILawyerInformation["additional_information"] =
          {
            ...lawyerData.additional_information,
          };
        additional_information.partneshipMemberApplication = newPartneshipTag;
        const res = await EditLawyerInformation(lawyerId, {
          additional_information,
        });

        if (newPartneshipTag === "approved") {
          await api.post(`/email/partneship_application_approved`, {
            name: userData.data.first_name,
            email: userData.data.email,
          });
        }
        if (newPartneshipTag === "rejected") {
          // await api.post(`/email/partneship_application_rejected`, {
          //   name: userData.data.first_name,
          //   email: userData.data.email,
          // });
        }
        message.success("Status do advogado alterado com sucesso");
        return console.log({ res });
      }
      const additional_information = {
        partneshipMemberApplication: newPartneshipTag,
      };
      const res = await EditLawyerInformation(lawyerId, {
        additional_information,
      });
      message.success("Status do advogado alterado com sucesso");
      return console.log({ res });
    }
  } catch (error) {
    message.error("Erro ao atualizar o status do advogado");
  }
}

export async function ChangeLawyerVerifiedState({
  lawyer_id,
  tag,
  description,
  show_lawyer,
}: ChangeLawyerVerifiedStatusProps): Promise<void> {
  try {
    console.log({
      lawyer_id,
      tag,
      description,
      show_lawyer,
    });
    await ChangeLawyerVerifiedStatus({
      lawyer_id,
      tag,
      description,
      show_lawyer,
    });
  } catch (error) {
    message.error(
      "Erro ao mudar o status da conta do advogado, tente novamente"
    );
  }
}
export type TagUserProp =
  | "Verificado"
  | "Não Verificado"
  | "Todos"
  | "Advogado"
  | "Usuário";

export const AllTagsArray = [
  "Todos",
  "Não Verificado",
  "Verificado",
  "Advogado",
  "Usuário",
];

export function defineUserTagColor(tag: TagUserProp): string {
  if (tag === "Verificado") {
    return "grey";
  } else if (tag === "Não Verificado") {
    return "red";
  } else if (tag === "Advogado") {
    return "orange";
  } else if (tag === "Usuário") {
    return "purple";
  }
  return "geekblue";
}

export function DefineUserTags(user: IUser): string[] {
  let tags = [];

  if (!user.active) {
    tags.push("Não Verificado");
  } else {
    tags.push("Verificado");
  }

  if (user.type === "lawyer") {
    tags.push("Advogado");
  }
  if (user.type === "user") {
    tags.push("Usuário");
  }

  return tags;
}
