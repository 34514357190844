import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import { Affix, Col, Divider, Drawer, Input, Row, Space } from "antd";
import queryString from "query-string";
import React, { useCallback, useRef, useState } from "react";
import {
  AiOutlineClose,
  AiOutlineCloseCircle,
  AiOutlineSearch,
} from "react-icons/ai";
import { BiSearch, BiSelectMultiple } from "react-icons/bi";
import { BsArrowLeft } from "react-icons/bs";
import { MdLocationOn } from "react-icons/md";
import { Link, useHistory } from "react-router-dom";
import { useTheme } from "styled-components";
import allOccupationAreasOptions, {
  LegalCorrespondenceOptions,
} from "../../assets/optionsData/allOccupationAreas";
import allSpecialtiesOptions from "../../assets/optionsData/allSpecialties";
import AllCitiesAndStates from "../../assets/optionsData/location/AllCitiesAndStates";

import { useAuth } from "../../hooks/auth";
import { useToast } from "../../hooks/Toast";
import Loading from "../Loading/Loading";
import SelectWindowed from "../SelectWindowed/SelectWindowed.Index";
import * as S from "./Styles.SprecialtiesMobileModalSelect";
const { Search } = Input;

interface LawyersSearchMobileStepsProps {
  privateRoute?: boolean;
  juridic_correspondence_search?: boolean;
  SetOptionSelected(option: React.SetStateAction<string>): void;
  OptionSelected: string;
  selectionType: "specialties" | "occupationArea" | "specialTiesAreaFlow";
}

const SpecialtiesMobileModalSelect: React.FC<LawyersSearchMobileStepsProps> = ({
  privateRoute,
  juridic_correspondence_search,
  OptionSelected,
  SetOptionSelected,
  selectionType,
}) => {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const [StepOne, SetStepOne] = useState(false);
  const [StepTwo, SetStepTwo] = useState(false);
  const [ToggleKey, SetToggleKey] = useState(0);

  const [SearchLoadingState, setSearchLoadingState] = useState(false);
  const [RenderSearchResult, setRenderSearchResult] = useState([] as any);
  const [SearchState, setSearchState] = useState(false);
  const [SearchValue, setSearchValue] = useState("");
  const { user } = useAuth();

  const { colors } = useTheme();

  const HandleToggleKey = useCallback(
    (key: number) => {
      SetToggleKey(key);
    },

    []
  );

  const showStepOne = () => {
    SetStepOne(true);
  };

  const onCloseStepOne = () => {
    SetStepOne(false);
  };

  const showStepTwo = () => {
    SetStepTwo(true);
  };

  const onCloseStepTwo = () => {
    SetStepTwo(false);
  };

  // --------------------------------------------------------------------------------------//
  //                                                                                      //
  //                                    Handle Submit                                     //
  //                                                                                      //
  // --------------------------------------------------------------------------------------//

  interface SubmitDataProps {
    searchLocation: string;
  }

  function FormSubmit() {
    formRef.current?.submitForm();
  }

  const { addToast } = useToast();

  function SearchForLawyers(data: any) {
    const Query = {
      location: data.searchLocation || "",
      specialty: OptionSelected || "",
    };

    const querystring = queryString.stringify(Query);

    history.replace(`/resultados/${querystring}`);

    if (privateRoute) {
      if (user.type === "lawyer") {
        history.replace(`/escritorio/resultados/${querystring}`);
      } else if (user.type === "user") {
        history.replace(`/painel/resultados/${querystring}`);
      }
    } else {
      history.replace(`/resultados/${querystring}`);
    }

    onCloseStepTwo();
    onCloseStepOne();
  }

  const handleSubmit = useCallback(
    (data: SubmitDataProps) => {
      try {
        if (!OptionSelected) {
          onCloseStepOne();
          return addToast({
            title: "Selecione no mínimo uma opção",
            type: "info",
          });
        }
        onCloseStepOne();
        // if (!data.searchLocation && !OptionSelected) {
        //   onCloseStepTwo();
        //   onCloseStepOne();
        //   return addToast({
        //     title: 'Selecione no mínimo uma opção',
        //     type: 'info'
        //   });
        // }
        // SearchForLawyers(data);
      } catch (error) {}
    },
    [OptionSelected, history]
  );

  // ---- Handle Search --------------------------------------------------------------------

  const onItemSearch = (value: any) => {
    setSearchValue(value);
    if (value.length >= 2) {
      setSearchLoadingState(true);

      const resultSpecialties = allSpecialtiesOptions.filter(function (data) {
        return data.label.toLowerCase().indexOf(value.toLowerCase()) > -1;
      });
      const resultOccupation = allOccupationAreasOptions
        .filter(function (data) {
          return data.options.map(
            (data) => data.label.toLowerCase().indexOf(value.toLowerCase()) > -1
          );
        })
        .map((data) => data.options)
        .flat();

      const joined = [...resultOccupation, ...resultSpecialties];

      const result = joined.flat().filter(function (data) {
        return data.label.toLowerCase().indexOf(value.toLowerCase()) > -1;
      });

      setRenderSearchResult(result);

      setSearchState(true);

      setSearchLoadingState(false);
    } else {
      setSearchState(false);
    }

    if (value.length < 2) {
      setSearchState(false);
    }
  };

  const ClearSearch = useCallback(() => {
    setSearchState(false);
    setSearchValue("");
  }, []);

  function functionThatSubmitsForm() {
    formRef.current?.submitForm();
  }

  return (
    <>
      <Row gutter={[32, 32]}>
        <Col
          span={24}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <S.FakeInput onClick={showStepOne}>
            <span>
              {OptionSelected ? OptionSelected : "Pesquise por especialidades"}
            </span>
            {OptionSelected ? (
              <BiSelectMultiple
                size={23}
                color="#5e5e5e"
                style={{ marginLeft: "0.3rem" }}
              />
            ) : (
              <BiSearch
                size={23}
                color="#5e5e5e"
                style={{ marginLeft: "0.3rem" }}
              />
            )}
          </S.FakeInput>
        </Col>
      </Row>

      <Drawer
        title={
          <div style={{ display: "flex" }}>
            Selecione{" "}
            {juridic_correspondence_search
              ? "um Serviço"
              : `uma Especialidade/Área de atuação`}
            <AiOutlineClose
              size={20}
              style={{ marginLeft: "auto", cursor: "pointer" }}
              onClick={() => {
                onCloseStepOne();
              }}
            />
          </div>
        }
        placement="right"
        closable={false}
        onClose={onCloseStepOne}
        visible={StepOne}
        width="100vw"
        zIndex={100000}
        bodyStyle={{ padding: 0, margin: 0, overflowX: "hidden" }}
        footer={
          <>
            <S.StyledButton
              // disabled={!OptionSelected}
              onClick={() => {
                functionThatSubmitsForm();
              }}
              style={{ float: "right" }}
            >
              Selecionar
            </S.StyledButton>
          </>
        }
      >
        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <Search
            style={{ marginBottom: "0rem", marginTop: "1.5rem", width: "80%" }}
            suffix={
              <>
                {SearchState && (
                  <button
                    type="button"
                    style={{ background: "none", border: "none" }}
                    onClick={ClearSearch}
                  >
                    <AiOutlineCloseCircle size={18} />
                  </button>
                )}
              </>
            }
            placeholder="Pesquise"
            onChange={(e) => onItemSearch(e.target.value)}
            value={SearchValue}
            enterButton={
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <AiOutlineSearch size={20} color={colors.dark} />
              </div>
            }
          />
        </div>
        <Loading loading={SearchLoadingState}>
          <Affix offsetTop={30}>
            <S.SearchTogglesArea>
              <Link to="#">
                <S.TogglesSelect
                  isActive={ToggleKey === 0}
                  onClick={(e) => HandleToggleKey(0)}
                >
                  {juridic_correspondence_search
                    ? "Serviços"
                    : "Áreas de Atuação"}
                </S.TogglesSelect>
              </Link>
              {!juridic_correspondence_search && (
                <Link to="#">
                  <S.TogglesSelect
                    isActive={ToggleKey === 1}
                    onClick={(e) => HandleToggleKey(1)}
                  >
                    Especialidades
                  </S.TogglesSelect>
                </Link>
              )}
            </S.SearchTogglesArea>
          </Affix>
          {juridic_correspondence_search ? (
            <>
              {ToggleKey === 0 && !SearchState && (
                <>
                  <S.OptionsArea>
                    <Space direction="vertical">
                      {LegalCorrespondenceOptions.map((data) => {
                        return (
                          <>
                            <section>
                              <Divider
                                orientation="left"
                                plain
                                style={{ marginBottom: "0.3rem" }}
                              >
                                <h2>{data.label}</h2>
                              </Divider>
                              <S.Rectangle style={{ marginLeft: "1.5rem" }} />

                              {data.options.map((item) => (
                                <>
                                  <S.Option
                                    key={item.label}
                                    isActive={OptionSelected === item.label}
                                    onClick={() =>
                                      SetOptionSelected(item.label)
                                    }
                                  >
                                    <h2>{item.label}</h2>
                                  </S.Option>
                                </>
                              ))}
                            </section>
                          </>
                        );
                      })}
                    </Space>
                  </S.OptionsArea>
                </>
              )}
            </>
          ) : (
            <>
              {ToggleKey === 0 && !SearchState && (
                <>
                  <S.OptionsArea>
                    <Space direction="vertical">
                      {allOccupationAreasOptions.map((data) => {
                        return (
                          <>
                            <section>
                              <Divider
                                orientation="left"
                                plain
                                style={{ marginBottom: "0.3rem" }}
                              >
                                <h2>{data.value}</h2>
                              </Divider>
                              <S.Rectangle style={{ marginLeft: "1.5rem" }} />

                              {data.options.map((item) => (
                                <>
                                  <S.Option
                                    key={item.label}
                                    isActive={OptionSelected === item.label}
                                    onClick={() =>
                                      SetOptionSelected(item.label)
                                    }
                                  >
                                    <h2>{item.label}</h2>
                                  </S.Option>
                                </>
                              ))}
                            </section>
                          </>
                        );
                      })}
                    </Space>
                  </S.OptionsArea>
                </>
              )}

              {ToggleKey === 1 && !SearchState && (
                <>
                  <S.OptionsArea>
                    <Space direction="vertical">
                      {allSpecialtiesOptions.map((data) => {
                        return (
                          <>
                            <S.Option
                              key={data.label}
                              isActive={OptionSelected === data.label}
                              onClick={() => SetOptionSelected(data.label)}
                            >
                              <h2>{data.label}</h2>
                            </S.Option>
                          </>
                        );
                      })}
                    </Space>
                  </S.OptionsArea>
                </>
              )}
            </>
          )}

          {SearchState && (
            <>
              <S.OptionsArea>
                <Space direction="vertical">
                  {RenderSearchResult.map((data: any) => {
                    return (
                      <>
                        <S.Option
                          key={data.label}
                          isActive={OptionSelected === data.label}
                          onClick={() => SetOptionSelected(data.label)}
                        >
                          <h2>{data.label}</h2>
                        </S.Option>
                      </>
                    );
                  })}
                </Space>
              </S.OptionsArea>
            </>
          )}
          <Form ref={formRef} onSubmit={handleSubmit}>
            <Drawer
              placement="right"
              closable={false}
              onClose={onCloseStepTwo}
              visible={StepTwo}
              width="100vw"
              zIndex={100000}
              title={
                <div style={{ display: "flex" }}>
                  Selecione uma Localidade
                  <BsArrowLeft
                    size={27}
                    style={{ marginLeft: "auto", cursor: "pointer" }}
                    onClick={onCloseStepTwo}
                  />
                </div>
              }
              footer={
                <>
                  <S.StyledButton
                    style={{ float: "right" }}
                    onClick={() => {
                      FormSubmit();
                    }}
                  >
                    Pesquisar
                  </S.StyledButton>
                </>
              }
            >
              <S.SearchLocation>
                <SelectWindowed
                  icon={MdLocationOn}
                  name="searchLocation"
                  placeholder="Selecione sua Localidade"
                  options={AllCitiesAndStates}
                  classNamePrefix="react-select-location"
                  className="react-select-container-location"
                />
              </S.SearchLocation>
            </Drawer>
          </Form>
        </Loading>
      </Drawer>
    </>
  );
};

export default SpecialtiesMobileModalSelect;
