import { Link } from "react-router-dom";
import styled from "styled-components";
import { DefaultButton } from "../../styles/DefaultButton";

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-grow: 5rem;
`;
export const ContentSection = styled.div`
  display: flex;
  flex: 1;

  background-repeat: no-repeat;
  background-color: ${(props) => props.theme.colors.background};
  object-fit: cover;
  .image {
    img {
      /* Editar este campo para alterar o tamanho da imagem */
      background-repeat: no-repeat;
      display: flex;
      height: 100vh;
      width: 100%;
      object-fit: cover;
    }
  }
`;

export const SignInSection = styled.div`
  display: flex;

  width: 100%;
  max-width: 500px;

  justify-content: center;
  align-items: center;

  color: ${(props) => props.theme.colors.text};
  background-color: ${(props) => props.theme.colors.whiteOrDark};

  border: 1.5rem solid ${(props) => props.theme.colors.whiteOrDark};
  border-radius: 0 1.2rem 1.2rem 0;

  padding: 2rem;

  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
`;

export const ContentSignIn = styled.div`
  h3 {
    flex-direction: row;
    color: ${(props) => props.theme.colors.lightText};
    text-align: center;
    font-size: 1rem;
  }
  .LogoAndPresentation {
    display: flex;
    justify-content: center;
    img {
      height: 4rem;
      margin-bottom: 1rem;
    }
    h3 {
      margin-top: 1rem;
      text-align: center;
    }
  }
  .LoginForm {
    transition: all 0.3s;

    h1 {
      color: ${(props) => props.theme.colors.title};
      margin-bottom: 1rem;
      margin-top: 1rem;
    }

    svg {
      color: ${(props) => props.theme.colors.lightGreyIcon};
      opacity: 0.5;
    }
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .additionalFormOptions {
    div {
      input {
        margin-right: 0.35rem;
      }
      display: flex;
      align-items: center;
      color: ${(props) => props.theme.colors.lightText};
    }
    font-size: 1rem;
    color: ${(props) => props.theme.colors.lightText};
    margin: 0.5rem 0 0.5rem 0;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  }
  .ButtonAndSignUp {
    margin: 1.5rem 0 0 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    p {
      margin: 1rem 0 0 0;
      color: ${(props) => props.theme.colors.lightText};
      text-align: center;
      font-size: 1rem;
    }
  }
`;

export const YellowText = styled.span`
  color: ${(props) => props.theme.colors.primary};
`;

export const DefaultButtonStyled = styled(DefaultButton)`
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const LinkStyled = styled(Link)`
  color: ${(props) => props.theme.colors.primaryDarker};
  text-decoration: none;
  font-size: 1rem;
  font-weight: 400;
`;
export const Footer = styled.footer`
  justify-content: left;
  align-items: left;
  padding-top: 4rem;
  text-align: left;
  color: grey;
  font-size: 0.8rem;
  .footer {
    font-size: 0.8rem;
    padding-top: 0.1rem;
  }
`;
