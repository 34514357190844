import { AxiosResponse } from "axios";
import { ILawyerInformation } from "../../@types/ILawyerInformation";
import api from "../../services/api";

export async function GetRandomPartneshipLawyers(): Promise<
  AxiosResponse<ILawyerInformation[]>
> {
  return await api.get("/lawyer/random");
}

export async function GetLawyerInformation(
  id: string
): Promise<AxiosResponse<ILawyerInformation>> {
  return await api.get<ILawyerInformation>(`/lawyer/id/${id}`);
}

export async function EditLawyerInformation(
  Lawyer_id: string,
  DataAPI: Partial<
    Omit<ILawyerInformation, "lawyer_id" | "updated_at" | "created_at">
  >
): Promise<AxiosResponse<ILawyerInformation>> {
  const data = {
    lawyer_id: Lawyer_id,
    ...DataAPI,
  };
  return await api.post<ILawyerInformation>(`/lawyer`, data);
}

export async function GetAllLawyersNumber(): Promise<
  AxiosResponse<ILawyerInformation[]>
> {
  return await api.get(`/lawyer`);
}

export interface ChangeLawyerVerifiedStatusProps {
  lawyer_id: string;
  tag: ILawyerInformation["verified"]["status"];
  description?: string;
  show_lawyer?: boolean;
}
export async function ChangeLawyerVerifiedStatus(
  dataAPI: ChangeLawyerVerifiedStatusProps
): Promise<AxiosResponse<ILawyerInformation[]>> {
  return await api.put(`/lawyer/admin/verified`, dataAPI);
}
