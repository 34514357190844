import styled from "styled-components";
import { DefaultStyledArea } from "../../../styles/DefaultStyledArea";

export const Container = styled.section`
  ${DefaultStyledArea.defaultDashboard}
  position: relative;

  margin: 1.5rem 4rem;
  padding: 1.5rem 1rem 0.2rem 1rem;

  @media only screen and (max-width: 768px) {
    margin: 2rem 1rem;
  }
`;
export const DemandHeader = styled.div`
  display: flex;
  flex-direction: row;

  align-items: center;
  margin-bottom: 10px;
`;
export const DemandTitle = styled.h2`
  margin-bottom: 1rem;
`;
export const DemandDescription = styled.p``;
export const DetailsArea = styled.div`
  padding-left: 1rem;
  margin-left: 1rem;

  padding-bottom: 0.5rem;
  margin-bottom: 1rem;

  padding-top: 0.5rem;
  margin-top: 1rem;

  border-left: 2px solid ${(p) => p.theme.colors.lightGrey};
`;

export const FooterContent = styled.footer`
  margin: 1rem 0.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
`;
