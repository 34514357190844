import { message } from "antd";
import React, { createContext, useCallback, useContext, useState } from "react";
import { Redirect } from "react-router-dom";
import api from "../services/api";

export interface UserProps {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  type: "lawyer" | "user";
  created_at: string;
  updated_at: string;
  active: boolean;
}

interface AuthState {
  token: string;
  refreshToken: string;
  user: UserProps;
}

interface SignInCredentials {
  email: string;
  password: string;
  plans?: boolean;
}

interface AuthContextData {
  user: UserProps;
  token: string;
  refreshToken: string;
  signIn(credentials: SignInCredentials): Promise<React.ReactNode>;
  signOut(): void;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

export const AuthProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<AuthState>(() => {
    const token = localStorage.getItem("Admin@DireitoPratico:token");
    const user = localStorage.getItem("Admin@DireitoPratico:user") || "{}";

    const refreshToken = localStorage.getItem(
      "Admin@DireitoPratico:refreshToken"
    );

    if (token && user && refreshToken) {
      api.defaults.headers.authorization = `Bearer ${token}`;

      return {
        token,
        user: JSON.parse(user),
        refreshToken,
      };
    }

    if (token && user && refreshToken) {
      api.defaults.headers.authorization = `Bearer ${token}`;
      return { token, user: JSON.parse(user), refreshToken };
    }

    return {} as AuthState;
  });

  const signIn = useCallback(async ({ email, password, plans }) => {
    const response = await api.post("/session", {
      email,
      password,
    });

    const { token, user, refreshToken } = response.data;
    if (user.type !== "admin") {
      message.error("Apenas admins tem acesso a está plataforma");
      throw new Error();
    }

    if (token) {
      // armazenamento dos dados no localStorage
      localStorage.setItem("Admin@DireitoPratico:token", token);
      localStorage.setItem("Admin@DireitoPratico:user", JSON.stringify(user));
      localStorage.setItem("Admin@DireitoPratico:refreshToken", refreshToken);

      api.defaults.headers.authorization = `Bearer ${token}`;

      setData({ token, user, refreshToken });
    } else {
      throw new Error("Invalid Login Credencial");
    }

    return <Redirect to="/dashboard" />;
  }, []);

  const signOut = useCallback(async () => {
    window.location.href = "/";
    localStorage.removeItem("Admin@DireitoPratico:token");
    localStorage.removeItem("Admin@DireitoPratico:user");
    localStorage.removeItem("Admin@DireitoPratico:refreshToken");

    setData({} as AuthState);
  }, []);

  return (
    //  {/*  O AuthContext.Provider dá a todos os componentes children acesso a informação
    // que ele contem, para inicializa-lo precisamos definir o 'value' */}
    <AuthContext.Provider
      value={{
        user: data.user,
        signIn,
        signOut,
        token: data.token,
        refreshToken: data.refreshToken,
      }}
    >
      {/* Tudo que o AuthProvider receber como filho (componente dentro do
        componente), será repassado para eles. */}
      {children}
    </AuthContext.Provider>
  );
};

// useAuth() criado componentização no arquivo do contexto para evitar o useContext(AuthContext); toda vez

export function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
}
