import { AxiosResponse } from "axios";
import api from "../../services/api";

export async function SendLawyerAccountVerifyingEmail(): Promise<
  AxiosResponse<void>
> {
  return await api.post(`/email/lawyer_verify_account`);
}

export async function SendOfficeAccountVerifyingEmail(): Promise<
  AxiosResponse<void>
> {
  return await api.post(`/email/office_verify_account`);
}
interface HostSendEmailStartingMeetingProps {
  host: string;
  link: string;
}
export async function SendHostVideoMeetingStartingEmail(
  dataApi: HostSendEmailStartingMeetingProps
): Promise<AxiosResponse<void>> {
  return await api.post(`/email/host_videoconferencing_starting_now`, dataApi);
}

interface props {
  id: string;
  link: string;
}

interface ParticipantsSendEmailStartingMeetingProps {
  data: props[];
}

export async function SendParticipantVideoMeetingStartingEmail(
  dataApi: ParticipantsSendEmailStartingMeetingProps
): Promise<AxiosResponse<void>> {
  return await api.post(
    `/email/participant_videoconferencing_starting_now`,
    dataApi
  );
}

export async function SendVerificationEmail(
  userId: string
): Promise<AxiosResponse<void>> {
  return await api.post(`/email/account_verification/${userId}`);
}
interface emailObjProps {
  email: string;
}
export interface SendEmailServiceProps {
  title: string;
  emailObj: emailObjProps[];
  html: string;
}
export function SendEmailHTML(dataApi: SendEmailServiceProps) {
  return api.post(`/email/personalized_email`, dataApi);
}
