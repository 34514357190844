import { useField } from "@unform/core";
import { opacify } from "polished";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { FiAlertTriangle } from "react-icons/fi";
import { IconBaseProps } from "react-icons/lib";
import { OptionTypeBase, Props as SelectProps } from "react-select";
import makeAnimated from "react-select/animated";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore: Unreachable code error
import WindowedSelect from "react-windowed-select";
import { useTheme } from "styled-components";
import * as Styled from "./styled.Select";

interface Props extends SelectProps<OptionTypeBase> {
  name: string;
  description?: string;
  label?: string;
  icon?: React.ComponentType<IconBaseProps>;
}

const SelectWindowed: React.FC<Props> = ({
  icon: Icon,
  description,
  name,
  label,
  ...rest
}) => {
  const [haveIcon, setHaveIcon] = useState(false);

  const handleIcon = useCallback(() => {
    setHaveIcon(!haveIcon);
  }, [haveIcon]);

  const selectRef = useRef(null);

  const { fieldName, defaultValue, registerField, error } = useField(name);

  const { colors: STheme } = useTheme();
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      getValue: (ref: any) => {
        if (rest.isMulti) {
          if (!ref.state.value) {
            return [];
          }
          return ref.state.value.map((option: OptionTypeBase) => option.value);
        }
        if (!ref.state.value) {
          return "";
        }
        return ref.state.value.value;
      },
    });
  }, [fieldName, registerField, rest.isMulti]);

  return (
    <Styled.StyledSelect isErrored={!!error} haveIcon={haveIcon}>
      <Styled.Label>
        <span>
          {Icon && handleIcon}
          {label}
        </span>
      </Styled.Label>
      <Styled.Icon>{Icon && <Icon size={20} />}</Styled.Icon>
      <WindowedSelect
        components={makeAnimated()}
        defaultValue={defaultValue}
        ref={selectRef}
        classNamePrefix="react-select"
        className="react-select-container"
        placeholder="Selecione..."
        theme={(theme: { colors: any }) => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            primary25: `${opacify(0.25, STheme.primary)}`,
            primary50: `${opacify(0.5, STheme.primary)}`,
            primary75: `${opacify(0.75, STheme.primary)}`,
            primary: `${STheme.primary}`,
          },
        })}
        {...rest}
      />
      {description && <p>{description}</p>}
      {error && (
        <Styled.Errors title={error}>
          <FiAlertTriangle color="red" size={20} />
        </Styled.Errors>
      )}
    </Styled.StyledSelect>
  );
};

export default SelectWindowed;
