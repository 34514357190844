/* eslint-disable react/no-children-prop */
/* eslint-disable react/display-name */
import React, { lazy, Suspense } from "react";
import { Route, Switch, BrowserRouter } from "react-router-dom";
import Loading from "../Components/Loading/Loading";
import AdminMeetingPage from "../Pages/AdminMeeting/AdminMeeting";
import DemandsSelectionPage from "../Pages/DemandsSelection/demandsSelection.index";
import UserList from "../Pages/UserList/UserList";
const SendEmail = lazy(() => import("../Pages/SendEmail/SendEmail"));

const Dashboard = lazy(() => import("../Pages/Dashboard/Dashboard"));
const LawyerList = lazy(() => import("../Pages/LawyerList/LawyerList"));
const PageNotFound = lazy(() => import("../Pages/NotFoundError/PageNotFound"));
const PGSignIn = lazy(() => import("../Pages/SignIn/signIn"));

import AuthRoute from "./routes";
const PrivateRoutesArray = [
  // shared
  {
    path: "/404",
    sidebar: () => <></>,
    main: () => <PageNotFound />,
  },
  // {
  //   path: "*",
  //   sidebar: () => <></>,
  //   main: () => <PageNotFound />,
  // },
  {
    path: "/lawyers-list",
    sidebar: () => <></>,
    main: () => <LawyerList />,
    private: true,
  },
  {
    path: "/meeting",
    sidebar: () => <></>,
    main: () => <AdminMeetingPage />,
    private: true,
  },
  {
    path: "/users-list",
    sidebar: () => <></>,
    main: () => <UserList />,
    private: true,
  },
  {
    path: "/email",
    sidebar: () => <></>,
    main: () => <SendEmail />,
    private: true,
  },
  {
    path: "/demandas",
    sidebar: () => <></>,
    main: () => <DemandsSelectionPage />,
    private: true,
  },
  {
    path: "/dashboard",
    sidebar: () => <></>,
    main: () => <Dashboard />,
    private: true,
  },
  {
    path: "/login",
    sidebar: () => <></>,
    main: () => <PGSignIn />,
  },
  {
    path: "/",
    sidebar: () => <></>,
    main: () => <PGSignIn />,
    exact: true,
  },
];

const Routes: React.FC = () => {
  return (
    <>
      {/* <Switch>
          {PrivateRoutesArray.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              exact={route.exact}
              children={route.sidebar}
            />
          ))}
        </Switch> */}
      <Suspense
        fallback={
          <>
            <Loading loading={true} fullPage>
              <div
                style={{
                  height: "100vh",
                  width: "100vw",
                }}
              ></div>
            </Loading>
          </>
        }
      >
        <Switch>
          {PrivateRoutesArray.map((route, index) => {
            return (
              <AuthRoute
                key={index}
                path={route.path}
                exact={route.exact}
                // children={route.main}
                component={route.main}
                isPrivate={route.private}
              />
            );
          })}
        </Switch>
      </Suspense>
    </>
  );
};

export default Routes;
