const allSpecialtiesOptions = [
  { value: 'Direito Acidentário', label: 'Direito Acidentário' },
  { value: 'Direito Administrativo', label: 'Direito Administrativo' },
  { value: 'Direito Aeronáutico', label: 'Direito Aeronáutico' },
  { value: 'Direito Agrário', label: 'Direito Agrário' },
  { value: 'Direito Ambiental', label: 'Direito Ambiental' },
  { value: 'Direito Bancário', label: 'Direito Bancário' },
  { value: 'Direito Canônico', label: 'Direito Canônico' },
  { value: 'Direito Civil', label: 'Direito Civil' },
  { value: 'Direito Constitucional', label: 'Direito Constitucional' },
  { value: 'Direito Consumidor', label: 'Direito Consumidor' },
  { value: 'Direito Contratual', label: 'Direito Contratual' },
  { value: 'Direito Corporativo', label: 'Direito Corporativo' },
  { value: 'Direito da Informática', label: 'Direito da Informática' },
  { value: 'Direito da Mulher', label: 'Direito da Mulher' },
  { value: 'Direito das Sucessões', label: 'Direito das Sucessões' },
  { value: 'Direito de Família', label: 'Direito de Família' },
  { value: 'Direito de Negócios', label: 'Direito de Negócios' },
  { value: 'Direito de Propriedade', label: 'Direito de Propriedade' },
  { value: 'Direito de Trânsito', label: 'Direito de Trânsito' },
  { value: 'Direito Desportivo', label: 'Direito Desportivo' },
  { value: 'Direito do Petróleo', label: 'Direito do Petróleo' },
  { value: 'Direito do Trabalho', label: 'Direito do Trabalho' },
  { value: 'Direito Educacional', label: 'Direito Educacional' },
  { value: 'Direito Eleitoral', label: 'Direito Eleitoral' },
  { value: 'Direito Empresarial', label: 'Direito Empresarial' },
  { value: 'Direito Espacial', label: 'Direito Espacial' },
  { value: 'Direito Financeiro', label: 'Direito Financeiro' },
  { value: 'Direito Imobiliário', label: 'Direito Imobiliário' },
  { value: 'Direito Internacional', label: 'Direito Internacional' },
  { value: 'Direito Marítimo', label: 'Direito Marítimo' },
  { value: 'Direito Médico', label: 'Direito Médico' },
  { value: 'Direito Militar', label: 'Direito Militar' },
  { value: 'Direito Minerário', label: 'Direito Minerário' },
  { value: 'Direito Penal', label: 'Direito Penal' },
  { value: 'Direito Previdenciário', label: 'Direito Previdenciário' },
  { value: 'Direito Processual Civil', label: 'Direito Processual Civil' },
  {
    value: 'Direito Processual do Trabalho',
    label: 'Direito Processual do Trabalho'
  },
  { value: 'Direito Processual Penal', label: 'Direito Processual Penal' },
  { value: 'Direito Sanitário', label: 'Direito Sanitário' },
  { value: 'Direito Securitário', label: 'Direito Securitário' },
  { value: 'Direito Societário', label: 'Direito Societário' },
  { value: 'Direito Tributário', label: 'Direito Tributário' },
  { value: 'Direito Urbanístico', label: 'Direito Urbanístico' },
  { value: 'Direitos Humanos', label: 'Direitos Humanos' }
];

export default allSpecialtiesOptions;
