import moment from "moment";
import React from "react";
import { AuthProvider } from "./auth";
import { ResponsivenessProvider } from "./Responsiveness";
import { ThemeProvider } from "./Theme";
import { ToastProvider } from "./Toast";

moment.locale("pt-br");
export const AppProvider: React.FC = ({ children }) => {
  return (
    <>
      <AuthProvider>
        <ResponsivenessProvider>
          <ThemeProvider>
            <ToastProvider>{children}</ToastProvider>
          </ThemeProvider>
        </ResponsivenessProvider>
      </AuthProvider>
    </>
  );
};
