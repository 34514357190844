import "dotenv/config";
import moment from "moment";
import { ChatUserProps } from "../../@types/IUser";
import { ToastMessage } from "../../hooks/Toast";
import api from "../../services/api";
import { compact } from "lodash";
import { SendEmailHTML } from "../RoutesFunctions/functions.email";
import { HostVideoMeetingStartingEmail } from "./EmailTemplates/HostVideoMeetingStartingEmail";
import { ParticipantVideoMeetingStartingEmail } from "./EmailTemplates/ParticipantVideoMeetingStartingEmail";
import { message } from "antd";

export interface VideoMeetingDataIntoMessageObjProps {
  senderLink: string;
  participantLink: string;
}

export type participantProps = {
  email: string;
  name: string;
};

export type linkParticipantProps = {
  email: string;
  name: string;
  token: string;
  link: string;
};

interface NewVideoMeetingProps {
  adminEmail: string;
  adminName: string;
  participant: participantProps;
  addToast: (
    message: Pick<ToastMessage, "type" | "title" | "description">
  ) => void;
  callBack?(data: VideoMeetingProps): void;
  otherParticipants?: participantProps[];
}

interface HandleVideoMeetingsProps {
  token: string;
}

export interface VideoMeetingProps {
  ParticipantData: participantProps;
  ParticipantLink: string;
  AdminLink: string;
  AdminEmail: string;
  otherParticipants?: linkParticipantProps[];
}

interface GetParticipantLinkProps {
  addToast: (
    message: Pick<ToastMessage, "type" | "title" | "description">
  ) => void;
  participant: participantProps;
  room: string;
  adminName: string;
}

async function GetParticipantLink({
  addToast,
  participant,
  room,
  adminName,
}: GetParticipantLinkProps) {
  // pegando token para outro usuário

  const DataRequestOther = {
    id: `${participant.name}`,
    room,
  };

  const responseParticipant = await api.post<HandleVideoMeetingsProps>(
    `/video_meeting`,
    DataRequestOther
  );

  if (responseParticipant.data === undefined) {
    addToast({ title: "Houve um Erro", type: "error" });
    return;
  }

  const participantToken = responseParticipant.data.token;

  const participantLink = `${process.env.REACT_APP_VIDEO_MEETINGS_URL}/room/${room}/${participantToken}`;

  //send email to participant

  await SendEmailHTML({
    emailObj: [
      {
        email: participant.email,
      },
    ],
    html: ParticipantVideoMeetingStartingEmail(
      participant.name,
      adminName,
      participantLink
    ),
    title: adminName + " te convidou para uma reunião de vídeo",
  });

  addToast({
    title: "Email enviado para participante",
    type: "info",
    description: participant.email,
  });

  return {
    token: participantToken,
    link: participantLink,
    name: participant.name,
    email: participant.email,
  };
}

interface GetAdminLinkProps {
  addToast: (
    message: Pick<ToastMessage, "type" | "title" | "description">
  ) => void;
  adminInfo: participantProps;
  room: string;
}

async function GetAdminLink({ addToast, adminInfo, room }: GetAdminLinkProps) {
  const DataRequest = {
    id: `${adminInfo.name}`,
    room,
  };

  // generate videoMeetingToken

  const responseUser = await api.post<HandleVideoMeetingsProps>(
    `/video_meeting`,
    DataRequest
  );

  if (responseUser.data === undefined) {
    addToast({ title: "Houve um Erro", type: "error" });
    return;
  }

  const adminVideoMeetingToken = responseUser.data.token;

  const adminLink = `${process.env.REACT_APP_VIDEO_MEETINGS_URL}/room/${room}/${adminVideoMeetingToken}`;

  // window.open(userLink, "_blank");

  // send email to admin
  await SendEmailHTML({
    emailObj: [
      {
        email: adminInfo.email,
      },
    ],
    html: HostVideoMeetingStartingEmail(adminInfo.email, adminLink),
    title: "Admin - Nova reunião de vídeo criada",
  });
  addToast({
    title: "Email enviado para admin",
    type: "info",
    description: adminInfo.email,
  });

  return {
    token: adminVideoMeetingToken,
    link: adminLink,
    name: adminInfo.name,
    email: adminInfo.email,
  };
}

export async function NewVideoMeeting({
  adminName,
  participant,
  addToast,
  adminEmail,

  callBack,
  otherParticipants,
}: NewVideoMeetingProps): Promise<VideoMeetingProps | undefined> {
  try {
    const room = `meeting-${Math.random()}`;

    const ParticipantMeetingData = await GetParticipantLink({
      addToast,
      adminName,
      participant,
      room,
    });

    const AdminMeetingData = await GetAdminLink({
      addToast,
      adminInfo: {
        email: adminEmail,
        name: adminName,
      },
      room,
    });

    if (!AdminMeetingData || !ParticipantMeetingData) {
      addToast({ title: "Houve um Erro", type: "error" });
      return;
    }
    let otherParticipantsData: linkParticipantProps[] | undefined = undefined;

    if (otherParticipants) {
      otherParticipantsData = [];
      const otherParticipantsPromise = otherParticipants.map(async (other) => {
        const otherParticipantMeetingData = await GetParticipantLink({
          addToast,
          adminName,
          participant: other,
          room,
        });

        return otherParticipantMeetingData;
      });
      return Promise.all(otherParticipantsPromise).then((data) => {
        otherParticipantsData = compact(data);
        const returnOBJ: VideoMeetingProps = {
      ParticipantData: {
        email: ParticipantMeetingData.email,
        name: ParticipantMeetingData.name,
      },
      ParticipantLink: ParticipantMeetingData.link,
      AdminLink: AdminMeetingData.link,
      AdminEmail: AdminMeetingData.email,
      otherParticipants: otherParticipantsData,
    };

    message.success("Reunião de vídeo criada com sucesso");
    callBack && callBack(returnOBJ);

    return returnOBJ;
        
      });
       
    }

    const returnOBJ: VideoMeetingProps = {
      ParticipantData: {
        email: ParticipantMeetingData.email,
        name: ParticipantMeetingData.name,
      },
      ParticipantLink: ParticipantMeetingData.link,
      AdminLink: AdminMeetingData.link,
      AdminEmail: AdminMeetingData.email,
      otherParticipants: otherParticipantsData,
    };

    message.success("Reunião de vídeo criada com sucesso");
    callBack && callBack(returnOBJ);

    return returnOBJ;
  } catch (error) {
    addToast({ title: "Houve um Erro", type: "error" });
  }
}
