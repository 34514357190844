import React, { useRef, useState } from "react";
import { Row, Col, Space, Tabs, Alert, Typography, Card, Divider } from "antd";
import PageLayout from "../../Components/Layout/Layout";
import { Helmet } from "react-helmet";

import styled from "styled-components";
import { ChatUserProps } from "../../@types/IUser";
import {
  NewVideoMeeting,
  participantProps,
  VideoMeetingProps,
} from "../../utils/videoMettings/functions.VideoMeetings";
import { useAuth, UserProps } from "../../hooks/auth";
import { useToast } from "../../hooks/Toast";
import { DefaultStyledArea } from "../../styles/DefaultStyledArea";
import { Form } from "@unform/web";
import Input from "../../Components/Input/InputIndex";
import { DefaultButtonStyled } from "../SignIn/styleSignIn";
import Loading from "../../Components/Loading/Loading";
import { has, dropRight, compact } from "lodash";
import { TransparentButton } from "../../styles/DefaultButton";
interface FormData {
  adminName: string;
  adminEmail: string;
  participantName: string;
  participantEmail: string;
}
const { Paragraph, Title } = Typography;
const { TabPane } = Tabs;

<Helmet>
  <title>Admin Meeting</title>

  <meta name="robots" content="Noindex" />
</Helmet>;

const Container = styled.div`
  padding: 1rem;
  align-items: center;
  justify-content: center;
  background: ${(props) => props.theme.colors.whiteOrDark};
  border-radius: 1.2rem;
  margin: 2rem;
  width: 100%;
  overflow-x: hidden;
`;

const DefaultSection = styled.div`
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) => props.theme.colors.whiteOrDark};
  border-radius: 1.2rem;
  margin: 2rem;

  ${DefaultStyledArea.defaultManualSize}
`;

interface setVideoMeetingsProps {
  participant: participantProps;
  admin: {
    name: string;
    email: string;
  };
  OthersParticipants?: participantProps[];
}

const AdminMeetingPage: React.FC = () => {
  const [LoadingState, SetLoading] = useState(false);

  const formRef = useRef(null);

  const [NewVideoMeetingData, SetNewVideoMeetingData] =
    useState<VideoMeetingProps>({} as VideoMeetingProps);

  const { addToast, ErrorToast } = useToast();

  type ParticipantNumber = {
    id: number;
  };

  const [ParticipantNumber, SetParticipantNumber] = useState<
    ParticipantNumber[]
  >([] as ParticipantNumber[]);

  function HandleAddParticipant() {
    SetParticipantNumber((n) => [...n, { id: n.length + 1 }]);
  }

  function HandleRemoveParticipant() {
    SetParticipantNumber((n) => dropRight(n));
  }

  const [IsNewVideoMeetingCreated, SetIsNewVideoMeetingCreated] =
    useState(false);

  function SetVideoMeeting({
    participant,
    admin,
    OthersParticipants,
  }: setVideoMeetingsProps) {
    return NewVideoMeeting({
      addToast,
      participant,
      adminEmail: admin.email,
      adminName: admin.name,
      otherParticipants: OthersParticipants,
    }).then((data) => {
      SetLoading(false);
      if (data !== undefined) {
        console.log(data)
        SetNewVideoMeetingData(data);
        return SetIsNewVideoMeetingCreated(true);
      }
      ErrorToast();
    });
  }

  function defineOthersParticipants(data: any) {
    if (ParticipantNumber.length === 0) {
      return undefined;
    }
    const othersParticipants: participantProps[] = ParticipantNumber.map(
      (item, index) => {
        console.log("compacted", compact(data.participant)[index], compact(data.participantNM)[index])

        const participantEmail: string = compact(data.participantNM)[index] as string
        const participantName: string = compact(data.participant)[index] as string
        return {
          name: participantName,
          email: participantEmail,
        };
      }
    );
    console.log('Others', othersParticipants)
    return othersParticipants;
  }

  function onSubmit(data: FormData) {
    const { adminName, adminEmail, participantName, participantEmail } = data;
    console.log({data})
    SetLoading(true);
    SetVideoMeeting({
      participant: {
        email: participantEmail,
        name: participantName,
      },
      admin: {
        email: adminEmail,
        name: adminName,
      },
      OthersParticipants: defineOthersParticipants(data),
    });
  }

  return (
    <>
      <PageLayout>
        <Loading loading={LoadingState}>
          <Row gutter={[32, 32]}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Container>
                <DefaultSection>
                  {has(NewVideoMeetingData, "AdminLink") ? (
                    <>
                      <Space direction="vertical" style={{ width: "100%" }}>
                        <Alert
                          message="Reunião criada com sucesso"
                          type="success"
                          description="Foi enviado um email contendo o link da reunião para o seu email e dos participantes"
                        />
                        <Card>
                          <Paragraph
                            copyable={{ text: NewVideoMeetingData.AdminLink }}
                          >
                            <a
                              href={NewVideoMeetingData.AdminLink}
                              target="_blank"
                              rel="noopener"
                            >
                              Link Anfitrião
                            </a>
                          </Paragraph>
                          <p style={{ fontSize: "0.8rem" }}>
                            Email enviado para {NewVideoMeetingData.AdminEmail}
                          </p>
                        </Card>
                        <Card>
                          <Paragraph
                            copyable={{
                              text: NewVideoMeetingData.ParticipantLink,
                            }}
                          >
                            Link Participante (
                            {NewVideoMeetingData.ParticipantData.name})
                          </Paragraph>
                          <p style={{ fontSize: "0.8rem" }}>
                            Email enviado para{" "}
                            {NewVideoMeetingData.ParticipantData.email}
                          </p>
                        </Card>
                        {NewVideoMeetingData.otherParticipants && (
                          <>
                            {NewVideoMeetingData.otherParticipants.map(
                              (item) => (
                                <>
                                  <Card>
                                    <Paragraph
                                      copyable={{
                                        text: item.link,
                                      }}
                                    >
                                      Link Participante ({item.name})
                                    </Paragraph>
                                    <p style={{ fontSize: "0.8rem" }}>
                                      Email enviado para {item.email}
                                    </p>
                                  </Card>
                                </>
                              )
                            )}
                          </>
                        )}
                        <DefaultButtonStyled
                          onClick={() => {
                            SetNewVideoMeetingData({} as VideoMeetingProps);
                          }}
                        >
                          Criar Nova Reunião
                        </DefaultButtonStyled>
                      </Space>
                    </>
                  ) : (
                    <>
                      <Form
                        onSubmit={onSubmit}
                        ref={formRef}
                        style={{ width: "100%" }}
                      >
                        <Title>Criar Reunião por Vídeo</Title>
                        <Divider />
                        <Space
                          direction="vertical"
                          style={{
                            width: "100%",
                          }}
                        >
                          <Title level={3}>Dados do Anfitrião</Title>

                          <Input
                            name="adminName"
                            label="Nome do Anfitrião"
                            placeholder="Nome do Anfitrião"
                            style={{
                              width: "100%",
                            }}
                          />
                          <Input
                            name="adminEmail"
                            label="Email do Anfitrião"
                            placeholder="Email do Anfitrião"
                            style={{
                              width: "100%",
                            }}
                          />
                        </Space>
                        <Divider />
                        <Space
                          direction="vertical"
                          style={{
                            width: "100%",
                          }}
                        >
                          <Title level={3}>Dados do Participante</Title>

                          <Input
                            name="participantName"
                            label="Nome do Participante"
                            placeholder="Nome do Participante"
                            style={{
                              width: "100%",
                            }}
                          />
                          <Input
                            name="participantEmail"
                            label="Email do Participante"
                            placeholder="Email do Participante"
                            style={{
                              width: "100%",
                            }}
                          />

                          {ParticipantNumber.map((participant, index) => (
                            <>
                            <Divider />
                              <Title level={3}>
                                Dados do Participante [{participant.id}]
                              </Title>

                              <Input
                                name={`participant[${participant.id}]`}
                                label="Nome do Participante"
                                placeholder="Nome do Participante"
                                style={{
                                  width: "100%",
                                }}
                              />
                              <Input
                                name={`participantNM[${participant.id}]`}
                                label="Email do Participante"
                                placeholder="Email do Participante"
                                style={{
                                  width: "100%",
                                }}
                              />
                            </>
                          ))}
                          <Space size='large'>
                            <TransparentButton
                              style={{ marginTop: 20 }}
                              onClick={HandleAddParticipant}
                              type="button"
                            >
                              Adicionar Participante
                            </TransparentButton>
                            {ParticipantNumber.length > 0 ? (
                              <TransparentButton
                              style={{ marginTop: 20 }}
                              onClick={HandleRemoveParticipant}
                              type="button"
                            >
                              Remover Participante
                            </TransparentButton>
                            ) : ''}
                            
                          </Space>
                          <DefaultButtonStyled style={{ marginTop: 30 }}>
                            Criar Reunião
                          </DefaultButtonStyled>
                        </Space>
                      </Form>
                    </>
                  )}
                </DefaultSection>
              </Container>
            </Col>
          </Row>
        </Loading>
      </PageLayout>
    </>
  );
};

export default AdminMeetingPage;
