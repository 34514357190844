import React, { useRef, useState } from "react";
import { Container, FilterOption } from "./styles";
import { Modal, Space, Typography } from "antd";
import { Form } from "@unform/web";
import { FormHandles } from "@unform/core";
import * as Yup from "yup";
import { AiOutlineClose } from "react-icons/ai";
import { useHistory } from "react-router";
import { useToast } from "../../../hooks/Toast";
import { useResponsiveness } from "../../../hooks/Responsiveness";
import AllCitiesAndStates from "../../../assets/optionsData/location/AllCitiesAndStates";
import SelectWindowed from "../../../Components/SelectWindowed/SelectWindowed.Index";
import SpecialtiesMobileModalSelect from "../../../Components/SprecialtiesMobileModalSelect/SprecialtiesMobileModalSelect";

const { Text } = Typography;
type FiltersStatesProps = {
  locationFilterValue: string;
  SetLocationFilterValue(value: React.SetStateAction<string>): void;
  SpecialtieFilterValue: string;
  SetSpecialtieFilterValue(value: React.SetStateAction<string>): void;
};

interface DemandFiltersProps {
  filters: FiltersStatesProps;
}
const DemandFilters: React.FC<DemandFiltersProps> = ({ filters }) => {
  const {
    SetLocationFilterValue,
    SetSpecialtieFilterValue,
    SpecialtieFilterValue,
    locationFilterValue,
  } = filters;
  const { addToast } = useToast();
  const { ScreenMD } = useResponsiveness();
  const [visible, setVisible] = React.useState(false);
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const [modalContent, setModalContent] = React.useState(<></>);
  const [modalTitle, setModalTitle] = React.useState("");

  const [filtersActiveStatus, setFiltersActiveStatus] = React.useState({
    date: false,
    location: false,
    specialtie: false,
  });
  const [SpecialtieSelectionStatus, SetSpecialtieSelectionStatus] =
    useState(false);
  type FilterOptionsProps = "locale" | "date" | "specialtie";

  const [Town, SetTown] = useState("");
  const [SpecialtieSelected, SetSpecialtieSelected] = useState("");

  const formRef = useRef<FormHandles>(null);
  const functionThatSubmitsForm = () => formRef.current?.submitForm();
  const RemoveFilter = (filterPreset: FilterOptionsProps) => {
    if (filterPreset === "date") {
      filtersActiveStatus.date = false;
      setFiltersActiveStatus(filtersActiveStatus);
    } else if (filterPreset === "locale") {
      filtersActiveStatus.location = false;
      setFiltersActiveStatus(filtersActiveStatus);
      SetLocationFilterValue("");
    } else if (filterPreset === "specialtie") {
      filtersActiveStatus.specialtie = false;
      setFiltersActiveStatus(filtersActiveStatus);
      SetSpecialtieSelectionStatus(false);
      SetSpecialtieSelected("");
      SetSpecialtieFilterValue("");
    }
  };
  const SetFilterModal = (filterPreset: FilterOptionsProps) => {
    if (filterPreset === "date") {
      setModalTitle("Filtro por data");
      setModalContent(<></>);
    } else if (filterPreset === "locale") {
      setModalTitle("Filtro por localização");

      const handleSubmit = async (data: any) => {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          town: Yup.string().required("Preencha este campo."),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        SetTown(data.town);

        filtersActiveStatus.location = true;
        setFiltersActiveStatus(filtersActiveStatus);
        SetLocationFilterValue(data.town);
      };
      setModalContent(
        <>
          <Form
            ref={formRef}
            onSubmit={(data) => {
              handleSubmit(data);
            }}
            style={{
              width: "100%",
            }}
          >
            <SelectWindowed
              name="town"
              label="Cidade"
              options={AllCitiesAndStates}
              style={{
                minWidth: "40rem",
              }}
            />
          </Form>
        </>
      );
    } else if (filterPreset === "specialtie") {
      setModalTitle("Filtro por Area de Atuação/Especialidade");
      SetSpecialtieSelectionStatus(true);
    }
    setVisible(true);
  };

  const handleOk = () => {
    if (SpecialtieSelectionStatus) {
      if (!SpecialtieSelected) {
        return addToast({
          title: "Selecione no mínimo uma opção",
          type: "error",
        });
      }
      filtersActiveStatus.specialtie = true;
      setFiltersActiveStatus(filtersActiveStatus);
      SetSpecialtieFilterValue(SpecialtieSelected);
      SetSpecialtieSelectionStatus(false);
    }
    setModalContent(<></>);
    setConfirmLoading(true);
    functionThatSubmitsForm();
    setVisible(false);
    setConfirmLoading(false);
  };

  const handleCancel = () => {
    setModalContent(<></>);
    if (SpecialtieSelectionStatus) SetSpecialtieSelectionStatus(false);
    console.log("Clicked cancel button");
    setVisible(false);
  };

  const ModalRender = () => (
    <Modal
      title={modalTitle}
      visible={visible}
      onOk={handleOk}
      confirmLoading={confirmLoading}
      onCancel={handleCancel}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          alignContent: "center",
          justifyContent: "center",
        }}
      >
        {SpecialtieSelectionStatus && (
          <SpecialtiesMobileModalSelect
            OptionSelected={SpecialtieSelected}
            SetOptionSelected={SetSpecialtieSelected}
            selectionType={"specialTiesAreaFlow"}
          />
        )}

        {modalContent}
      </div>
    </Modal>
  );

  const history = useHistory();

  const FilterOptions = [
    {
      title: `Localização${
        locationFilterValue ? ` - ${locationFilterValue}` : ""
      }`,
      onClick: () => SetFilterModal("locale"),
      onRemove: () => RemoveFilter("locale"),
      active: filtersActiveStatus.location,
    },
    {
      title: `Area de Atuação/Especialidade${
        SpecialtieFilterValue ? ` - ${SpecialtieFilterValue}` : ""
      }`,
      onClick: () => SetFilterModal("specialtie"),
      onRemove: () => RemoveFilter("specialtie"),
      active: filtersActiveStatus.specialtie,
    },

    // {
    //   title: 'Data',
    //   onClick: () => SetFilterModal('date'),
    //   onRemove: () => RemoveFilter('date'),
    //   active: filtersActiveStatus.date
    // }
  ];
  return (
    <>
      {ModalRender()}
      <Container
        style={{
          flexDirection: ScreenMD ? "column" : "row",
          alignItems: ScreenMD ? "flex-start" : "center",
        }}
      >
        <Text
          style={{
            fontSize: "0.8rem",
            marginBottom: ScreenMD ? 16 : 0,
            marginLeft: ScreenMD ? 16 : 0,
          }}
        >
          Filtrar por{" "}
        </Text>
        <Space direction="horizontal" wrap>
          {FilterOptions.map((value) => {
            return (
              <FilterOption active={value.active} onClick={value.onClick}>
                {value.title}
                {value.active && (
                  <AiOutlineClose
                    style={{
                      marginLeft: 5,
                    }}
                    onClick={value.onRemove}
                    size={15}
                  />
                )}
              </FilterOption>
            );
          })}
        </Space>
      </Container>
    </>
  );
};

export default DemandFilters;
