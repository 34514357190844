import { Col, message, Row } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { IUser } from "../../@types/IUser";

import LawyersListTable from "../../Components/LawyerListTable/LawyersListTable";
import PageLayout from "../../Components/Layout/Layout";
import Loading from "../../Components/Loading/Loading";
import UserListTable from "../../Components/UserListTable/UserListTable";
import { GetAllLawyersNumber } from "../../utils/RoutesFunctions/functions.Lawyers";
import { GetAllUsersNumber } from "../../utils/RoutesFunctions/functions.Users";

const UserList: React.FC = () => {
  const [AllUserData, SetAllUserData] = useState<IUser[]>([] as IUser[]);

  const [LoadingState, SetLoadingState] = useState(true);

  async function FetchData() {
    try {
      SetLoadingState(true);
      const users = await GetAllUsersNumber();
      const userFiltered = users.data.sort((a, b) =>
        moment(moment(b.created_at).unix()).diff(moment(a.created_at).unix())
      );

      SetAllUserData(userFiltered);
      SetLoadingState(false);
    } catch (error) {
      SetLoadingState(false);
      console.log(error);
      message.error("Erro ao Solicitar os arquivos de estatísticas");
    }
  }

  useEffect(() => {
    FetchData();
  }, []);

  return (
    <>
      <PageLayout>
        <Loading loading={LoadingState}>
          <div style={{ padding: "1rem" }}>
            <Row gutter={[32, 32]} justify="center">
              <Col xs={24} sm={24} md={22} lg={22} xl={22}>
                <UserListTable
                  userData={AllUserData}
                  LoadingState={LoadingState}
                />
              </Col>
            </Row>
          </div>
        </Loading>
      </PageLayout>
    </>
  );
};

export default UserList;
