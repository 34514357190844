import { message, notification } from "antd";
import React, { createContext, useCallback, useContext, useState } from "react";

import { useResponsiveness } from "./Responsiveness";

interface ToastContextData {
  addToast(message: Omit<ToastMessage, "id">): void;

  ErrorToast(): void;
}

export interface ToastMessage {
  id: string;
  type?: "success" | "error" | "info";
  title: string;
  description?: string;
}

const ToastContext = createContext<ToastContextData>({} as ToastContextData);

export const ToastProvider: React.FC = ({ children }) => {
  const { ScreenMD } = useResponsiveness();
  const [messages, setMessages] = useState<ToastMessage[]>([]);

  const addToast = useCallback(
    ({ type, title, description }: Omit<ToastMessage, "id">) => {
      const Notification = () => {
        notification.open({
          style: { zIndex: 100000002 },
          message: title,
          description: description,
          type: type,
          top: ScreenMD ? undefined : 24,
          placement: ScreenMD ? "bottomRight" : "topRight",
        });
      };
      Notification();

      // setMessages(oldMessages => [...oldMessages, toast]);
    },
    []
  );

  const ErrorToast = (title?: string) => {
    // addToast({
    //   title: title || 'Houve um erro, tente novamente',
    //   description: description || undefined,
    //   type: 'error'
    // });
    message.error(title);
  };

  return (
    <ToastContext.Provider value={{ addToast, ErrorToast }}>
      {children}
    </ToastContext.Provider>
  );
};

export function useToast(): ToastContextData {
  const context = useContext(ToastContext);

  if (!context) {
    throw new Error(
      "useToast must be used in a children component of ToastProvider"
    );
  }

  return context;
}
