import axios from "axios";
import Backend from "../config/backendAdress";
import "dotenv/config";
const api = axios.create({
  baseURL: Backend.baseURL,
  headers: {
    admin_hash: process.env.REACT_APP_ADMIN_HASH || "",
  },
});

// ---- Ensure Valid Token ---------------------------------------------------------------

export default api;
