import { css } from 'styled-components';

export const DefaultStyledArea = {
  default: css`
    width: 60rem;
    max-width: 68rem;
    min-width: 55rem;

    padding: 2rem;
    margin-top: 2rem;
    margin-bottom: 2rem;

    background: #ffffff;
    box-shadow: 7px 9px 14px -8px rgba(0, 0, 0, 0.25);
    border-radius: 1.2rem;
  `,
  defaultManualSize: css`
    padding: 2rem;
    margin-top: 2rem;
    margin-bottom: 2rem;

    background: #ffffff;
    box-shadow: 7px 9px 14px -8px rgba(0, 0, 0, 0.25);
    border-radius: 1.2rem;
  `,
  defaultDashboard: css`
    padding: 2rem;

    background: #ffffff;
    box-shadow: 7px 9px 14px -8px rgba(0, 0, 0, 0.25);
    border-radius: 1.2rem;

    @media only screen and (max-width: 720px) {
      padding: 1.2rem;
    }
  `,
  defaultPadding: css`
    padding: 0 1.5rem 0 1.5rem 0;
  `
};
